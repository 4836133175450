import React from "react";
import { Link } from 'react-router-dom';
import './insurance-style.scss';
import Navbar from "../../../components/common/navbar/navbar";
import Footer from "../../../components/common/footer/footer";
import { FaClock} from "react-icons/fa";
import { callTealium,getUserNameFromLocal,getOrgName, capturePageActiveTime,isUserLoggedIn, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { toastSuccess, toastError } from '../../../utils/toaster';

export default class Insurance extends React.Component {
    constructor() {
        super();
        
        this.ForceEventEntry = this.ForceEventEntry.bind(this);
    }
    async ForceEventEntry(){
        let response = await capturePageActiveTime("Insurance", 2*60);
        toastSuccess('Simulated 2 mins wait here');
   }
   async ForceCDPEntry(){
    let response = await callTealium(getUserNameFromLocal(),"PageView","Insurance", "Insurance", 2*60);
    toastSuccess('Simulated 2 mins wait here via Tealium');
}
    render() {
        return (
            <>
            <Navbar></Navbar>
            <div className={getOrgName()+"-products-container"}>
            <section className={getOrgName()+"-page-background columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Insurance Offer
                   
                    </p>
                    <p>
                    A 15-year mortgage is a loan for buying a home whereby the interest rate and monthly payment are 
                    fixed throughout the life of the loan. Fixed-rate conventional mortgages are sometimes called "vanilla wafer"
                     mortgage loans. That’s because they’re simple.
                   </p>
                </div>
            </section>
            <section className={getOrgName()+"-page-background content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-initial-content">Simplify your banking and save</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <div className="savings-title"><p>SavingsPLUS</p></div>
                            <div className="savings-sub-title"><p>High every day savings rates.</p></div>
                            <div className="mt-5"><p>3% interest</p></div>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <div className="savings-title"><p>Flex Checking</p></div>
                            <div className="savings-sub-title"><p>Low minimum balance</p></div>
                            <div className="mt-5"><p>No ATM fees</p></div>
                        </div>
                        <div className="column is-3 p-0 savings-container">
                            <div className="savings-title"><p>IQZ+ Credit Card PLUS</p></div>
                            <div className="savings-sub-title"><p>Earn rewards for regular payments and low revolving balance</p></div>
                            {isUserAlreadyLoggedIn()==true?
                            <div className=" columns mt-5">
                            <div className="column"><FaClock/> <a  onClick={this.ForceCDPEntry}><p>2 mins via Tealium </p></a></div>
                            <div className="column"><FaClock/> <a  onClick={this.ForceEventEntry}><p>2 mins via Pega Stream</p> </a></div>
                            </div>
                            :
                            ""}
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-page-background content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                            <img src="/images/Insurance.jpg" alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <div className="savings-title is-size-4"><p>Insurance</p></div>
                                <div className="savings-sub-title mt-3">
                                <p>Fixed-rate conventional mortgages are sometimes called "vanilla wafer"
                     mortgage loans. That’s because they’re simple.</p>
                                </div>
                                {/* {isUserLoggedIn()==true?
                                    <button className={"button savings-button mt-5 "+(isButtonLoading?'is-loading':null)} onClick={clickAd}>Apply now</button>
                                    :""
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-page-background columns m-0 is-centered content-spacing"}>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Simplify your banking and start earning more.
                    </p>
                    {/* {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                    }
                     */}
                </div>
            </section>
            </div>
            <Footer></Footer>
        </>
    );
         
    }
}
