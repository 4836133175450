import './header-style.scss';
import React from "react";
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
import { getAds, captureClick } from "../../../utils/ads-interaction";
import { Link, useHistory } from "react-router-dom";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: {}
        };
        this.getContent = this.getContent.bind(this);
        this.attachCarousel = this.attachCarousel.bind(this);
        this.adDetail = this.adDetail.bind(this);
    }
    
    async  adDetail(data) {
        // let history = useHistory();
        const response = await captureClick(data);
        // this.props.history.push.push("/learn-more", data);
    }
    attachCarousel(){
        var carousels = bulmaCarousel.attach('#post_images', {
            slidesToScroll: 1,
            slidesToShow: 1,
            effect: 'translate',
            duration: 100,
            autoplay:true,
            infinite: true,
        });
    }
    async componentDidMount() {
        await this.getContent();
        
      }
      async getContent() {
        
        const response = await getAds();
        await this.setState({
            response: response
        })
        //Carousel atleast need two slides to scroll
        if(this.state.response.ContainerList && this.state.response.ContainerList[0].RankedResults && (this.state.response.ContainerList[0].RankedResults).length>1){
            this.attachCarousel();
        }
        
    }
    render() {
        return (
            <>
            {this.state.response.ContainerList && this.state.response.ContainerList[0].RankedResults && (this.state.response.ContainerList[0].RankedResults).length>1?
                    <div class="page-background mb-0 columns is-vcentered">
                    <div class="column">
                        <section class="">
                        <div class="container carousel-conainter-margin" >
                            <div id="post_images" className="hero-carousel">
                            {
                                this.state.response.ContainerList[0].RankedResults.map((data) => {
                                    return (
                                        <div className="columns is-vcentered" key={data.Name}>
                                           <div className="column m-5" key={data.Name}>
                                                <div>
                                                    <img style={{ width: "630px", height:"430px"}} className="domain-image" src={data.ImageURL} alt="" />
                                                </div>
                                               
                                            </div>
                                            <div className="column ">
                                                    <p className="topic">{data.Label}</p>
                                                    <p className="is-size-6 is-family-code has-text-weight-light mt-2">{data.ShortDescription}</p>
                                                    <Link to={{  pathname: "/learn-more",  state: data }}  onClick={() => this.adDetail(data)}  id={data.Name} className="button mt-4 secondary-button">Learn more</Link>
                                            </div>  
                                        </div>
                                    )
                                })
                            
                            }
                            </div>
                        </div>
                    </section>
                    </div>
                    </div>
                    : null
            }
                
            
            </>
        )
    }
}