import React from "react";
import "./request-form-style.scss";
import { toastSuccess, toastError } from '../../../utils/toaster';
import { getOrgName } from "../../../utils/ads-interaction";
import {useRef} from 'react';


export default class RequestForm extends React.Component {
    
    constructor() {
        super();
        this.state = {
            isButtonLoading: false
        };
        this.submitFeedback = this.submitFeedback.bind(this);
        this.handleFormClose = this.handleFormClose.bind(this);
    }

    async submitFeedback() {
        await this.setState({
            isButtonLoading: true
        });
        let PC="";
        var ele = document.getElementsByName('PC');
          
        for(let i = 0; i < ele.length; i++) {
            if(ele[i].checked)
            PC = ele[i].value;
        }
        let resquestType = this.menu.value;
        const responses = new Set();
        const userData = JSON.parse(localStorage.getItem('data'));
        let response;
        const requestBody = {
            GlobalContactID:"00DE56E152A6C5E1AA00081001",
            CustomerName: userData.username, 
            RequestType:resquestType, 
            Comments:this.message.value, 
            PreferenceType:PC 
        };
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Authorization": "Basic " + window.btoa("CRMServiceOperatorForPOCs:Dec122023") },
            body: JSON.stringify(requestBody)
        };
        let serverURL = 'https://prmrca-prmagt-dt1.pegacloud.net/';
        try {
            response = await fetch(serverURL+'prweb/api/SAServices/v1/ClientRequest', requestOptions);
           // response = await response.json();
            if (response.Status === "OK") {
                responses.add(true);
            }
            
        } catch (err) {
            responses.add(false);
        }
        console.log(JSON.stringify(responses));
        await this.setState({
            isButtonLoading: false,
            modalVisible: false
        });
        if (responses.has(false)) {
            toastError('There was an error in submitting the values. Please try again later');
        } else {
            toastSuccess('We have received your request.'+'\n'+'Agent will reach you shortly.'+'\n'+'Thank you!');
        }
        this.handleFormClose();
        return;
    }

    handleFormClose() {
        this.props.handleFormClose();
    }

    
    render() {
        return (
            <div className={"modal " + (this.props.isFormActive ? 'is-flex' : null)}>
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">What are you looking for?</p>
                <button class="delete" onClick={this.handleFormClose} aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                <div class="select is-primary">
                    <select ref = {(input)=> this.menu = input}>
                        <option>Increase life insurance coverage </option>
                        <option>Obtain life insurance coverage</option>
                        <option>Open a investment or saving account</option>
                        <option>Increase my investment contribution(s)</option>
                        <option>Learn more about investing</option>
                        <option>Questions about my life insurance</option>
                        <option>Questions about my investment(s)</option>
                        <option>General question</option>
                    </select>
                </div>
                <div class="field mt-4">
                    <div class="control">
                        <label className="mr-2">Preferred contact</label>
                        <label class="radio">
                        <input type="radio" 
                                name="PC"
                                value="Email" />
                        Email
                        </label>
                        <label class="radio">
                        <input type="radio" name="PC"
                                value="Phone" checked/>
                        Phone
                        </label>
                    </div>
                </div>
                <div className="mt-4">
                    <textarea ref = {(input)=> this.message = input} id="message" name="message" class="textarea is-info" placeholder="Comments"></textarea></div>
                </section>
                <footer class="modal-card-foot">
                <button className={"button slider-custom-submit mr-4 " + (this.state.isButtonLoading ? 'is-loading' : null)}
                 onClick={this.submitFeedback}>Submit</button>
                {/* <button class="button" onClick={this.handleFormClose}>Cancel</button> */}
                </footer>
            </div>
            </div>
        );
    }
}