import React from "react";
import { Link } from 'react-router-dom';
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { FaClock} from "react-icons/fa";
import {  callTealium, getUserNameFromLocal, getOrgName, capturePageActiveTime, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { toastSuccess, toastError } from '../../../utils/toaster';

export default class Sunlife_OtherInsurance extends React.Component {
    constructor() {
        super();
        
        this.ForceEventEntry = this.ForceEventEntry.bind(this);
    }
    async ForceEventEntry(){
        let response = await capturePageActiveTime("OtherInsurance", 2*60);
        toastSuccess('Simulated 2 mins wait here');
   }
   async ForceCDPEntry(){
    let response = await callTealium(getUserNameFromLocal(),"PageView","OtherInsurance", "OtherInsurance", 2*60);
    toastSuccess('Simulated 2 mins wait here via Tealium');
}
    render() {
        return (
            <>
            <Navbar></Navbar>
           .
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Other Insurance Offer
                   
                    </p>
                    <p>
                    Get the right coverage, wherever you are in life.Protect your family, 
                    your business and your legacy with life insurance.Fill in the blanks your government 
                    plan leaves, with health insurance from Sun Life.</p>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-initial-content">Buy health insurance online</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Mortgage protection</p>
                            <p className="savings-sub-title">t offers your family or beneficiaries a certain amount of money if you were to die.</p>
                            <p className="mt-5">Sun Life Term Life Insurance </p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Family insurance</p>
                            <p className="savings-sub-title">Doing the right thing when it comes to their future is important</p>
                            <p className="mt-5">Term life insurance</p>
                        </div>
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-title">Workplace benefits</p>
                            <p className="savings-sub-title">Your employee benefits plan is a valuable asset for you and your family</p>
                            
                            {isUserAlreadyLoggedIn()==true?
                            <div style={{color:"black"}}  className=" columns mt-5">
                            <div className="column"><FaClock/> <a style={{color:"black"}}  onClick={this.ForceCDPEntry}>2 mins via Tealium </a></div>
                            <div className="column"><FaClock/> <a style={{color:"black"}}  onClick={this.ForceEventEntry}>2 mins via Pega Stream </a></div>
                            </div>
                            :
                            ""}
                            </div>
                        
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                        <img src="/images/SAOtherInsurance.jpg" alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <p className="savings-title is-size-4">Other Insurance</p>
                                <p className="savings-sub-title mt-3">Fixed-rate conventional mortgages are sometimes called "vanilla wafer"
                     mortgage loans. That’s because they’re simple.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className={getOrgName()+"-container columns m-0 is-centered content-spacing" }>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Talk to an advisor to buy insurance or Buy health insurance online.
                    </p>
                    {/* {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                    }
                     */}
                </div>
            </section>
            <Footer></Footer>
        </>
    );
         
    }
}
