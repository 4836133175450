import React from "react";
import './feedback-details.scss';
import AccountOverview from "../../account/account-overview/account-overview";
import Ads from "../../account/ads/ads";
import QuickLinks from "../../account/quick-links/quick-links";
import Bundled from "../../account/bundled/bundled";
import { getAds, getOrgName, SubmitStandaloneFeedback} from "../../../utils/ads-interaction";
import RetailRecommendations from "../../account/retail-recommendations/retail-recommendations";
import { toastSuccess } from '../../../utils/toaster';
import { Link } from 'react-router-dom';
import { FaUndoAlt } from "react-icons/fa";

export default class StandaloneFeedback extends React.Component {
    constructor() {
        super();
        this.SubmitStandaloneFeedback = this.SubmitStandaloneFeedback.bind(this);
    }
    
    async SubmitStandaloneFeedback(){
        const urlParams = new URLSearchParams(decodeURIComponent(window.location.href));;
        let contactid = "NA";
        contactid = urlParams.get('SID');
        contactid = contactid=== null ? "NA" : contactid;
        console.log(contactid);
        let q1Ans = "";
        let q2Ans = "";
        var ele = document.getElementsByName('Q1');
          
        for(let i = 0; i < ele.length; i++) {
            if(ele[i].checked)
            q1Ans = ele[i].value;
        }
        ele = document.getElementsByName('Q2');
          
        for(let i = 0; i < ele.length; i++) {
            if(ele[i].checked)
            q2Ans = ele[i].value;
        }
        await SubmitStandaloneFeedback(contactid, q1Ans, q2Ans);
        toastSuccess('Response Captured');
    }

    render() {
        return (
            <>
               <section className={getOrgName()+"-feedbackdetails"}>
                <div className="container" style={{"height":"100vh"}}>
                    <div className="columns ">
                        <div className="column">
                            <div class="container box p-6    has-background-light">
                            <h1 class="title has-text-centered
                                has-text-success">
                                Feedback Form
                            </h1>
                                <div class="field">
                                    <label class="label">When are you planning to make your first Sales Call?</label>
                                    
                                </div>
                                <div class="field">
                                    <div class="control">
                                        <label class="radio">
                                        <input type="radio" 
                                                name="Q1"
                                                value="In 2 Days" />
                                        In 2 Days
                                        </label>
                                        <label class="radio">
                                        <input type="radio" name="Q1"
                                                value="In a Week" />
                                        In a Week
                                        </label>
                                        <label class="radio">
                                        <input type="radio" name="Q1"
                                                value="No Plan" />
                                        No Plan
                                        </label>
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Are you comfortable interacting with Team and RVP?</label>
                                    
                                </div>
                                <div class="field">
                                    <div class="control">
                                        <label class="radio">
                                        <input type="radio" 
                                                name ="Q2"
                                                value="Yes" />
                                        Yes
                                        </label>
                                        <label class="radio">
                                        <input type="radio" 
                                                name ="Q2"
                                                value="No" />
                                        No
                                        </label>
                                    </div>
                                </div>
                                <div class="buttons is-centered">
                                    <button onClick={this.SubmitStandaloneFeedback} class="button is-success is-fullwidth">
                                    Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                
            </>
        );
    }

}