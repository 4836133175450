import "./account-overview-style.scss";
import React from "react";
import AdRecommendations from "../ad-recommendations/ad-recommendations";
import Bundled from "../bundled/bundled";
import { getOrgName, getWalletUrl } from "../../../utils/ads-interaction";

export default class AccountOverview extends React.Component {
   
    render() {
        const iconUrl = getWalletUrl();
        return (
            <>{
                getOrgName()=='iqz' || getOrgName()=='finance' || getOrgName()=='primerica'?
            <span>
            <p className={getOrgName()+"-container account-title"}>Account overview</p>
            <div className={getOrgName()+"-container columns m-0"}>
                <div className="column pl-0">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column is-4">
                                <img src={iconUrl} alt="" />
                            </div>
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Savings</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle"}>***1234</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column is-4">
                                <img src={iconUrl} alt="" />
                            </div>
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Checking</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle"}>***5678</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column is-4">
                                <img src={iconUrl} alt="" />
                            </div>
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Credit</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle"}>***7890</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className={getOrgName()+"-container account-title mt-6"}>Credit card balance</p>
            <div className={getOrgName()+"-container columns m-0"}>
                <div className="column pl-0">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Current balance</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle mt-2"}>$164.80</div>
                                <button className={getOrgName()+"-button button mt-4 is-size-7"}>Pay now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Due date</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle mt-2"}>Sep 15, 2021</div>
                                <button className={getOrgName()+"-button button mt-4 is-size-7"}>View statement</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>View statement</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle mt-2"}>$164.80</div>
                                <button className={getOrgName()+"-button button mt-4 is-size-7"}>Suspend Card Protect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </span>:
            getOrgName()=='insurance' ?
            <span>
            <p className={getOrgName()+"-container account-title"}>Insurance overview</p>
            <div className={getOrgName()+"-container columns m-0"}>
                <div className="column pl-0">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column is-4">
                                <img src={iconUrl} alt="" />
                            </div>
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Policy ID</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle"}>***1234</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column is-4">
                                <img src={iconUrl} alt="" />
                            </div>
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Customer ID</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle"}>***5678</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column is-4">
                                <img src={iconUrl} alt="" />
                            </div>
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Insured amount</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle"}>$***7890</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className={getOrgName()+"-container account-title mt-6"}>Insurance Details</p>
            <div className={getOrgName()+"-container columns m-0"}>
                <div className="column pl-0">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Renewal Amount</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle mt-2"}>$164.80</div>
                                <button className={getOrgName()+"-button button mt-4 is-size-7"}>Pay now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>Due date</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle mt-2"}>Sep 15, 2021</div>
                                <button className={getOrgName()+"-button button mt-4 is-size-7"}>View statement</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="account-card-container">
                        <div className="columns m-0">
                            <div className="column">
                                <div className={getOrgName()+"-card-title account-card-title"}>View statement</div>
                                <div className={getOrgName()+"-card-subtitle account-card-subtitle mt-2"}>$164.80</div>
                                <button className={getOrgName()+"-button button mt-4 is-size-7"}>Last Paid details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </span>
            :

            <div class="columns">
                here
                <div class="column is-one-third imagebackground" >
                    <img style={{ width: "350px", height:"250px"}} className={getOrgName()+"-image  domain-image"} src="/images/sunlifeabout.jpg" alt="" />
                </div>
                <div class="column">
                    <p className={getOrgName()+"-container account-title "}>Trusted for generations</p>
                    <p className="is-size-6 is-family-code  mt-2" style={{color:"black", fontFamily: "sunlife-sans", fontWeight: 400}} >
                    Sun Life’s roots run deep in Canada, where our company began more than 150 years ago.
                     Our business started with the sale of insurance and has expanded to offer wealth solutions and
                      customized health programs to our clients. Today we are an industry leader touching the lives of millions of 
                      individuals and thousands of companies across the country – 
                    and many more millions of clients around the world.
                    </p>
                </div>
            </div>
            }
                
                <p className={getOrgName()+"-container account-title mt-6"}>Recommendations</p>
                <div className="columns m-5">
                    <AdRecommendations data={this.props.data}></AdRecommendations>
                </div>
                {getOrgName()=='insurance' ?
                <div className={getOrgName()+"-container transactions-container"}>
                    <p className="account-title">Recent renewals</p>
                    <table className="table is-fullwidth mt-5">
                        <tbody>
                            <tr>
                                <td className="pl-0">AUG 15, 2021</td>
                                <td>Your last statement is ready to view</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                            <tr>
                                <td className="pl-0">AUG 13, 2021</td>
                                <td>Interest Charge: Purchases</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                            <tr>
                                <td className="pl-0">AUG 11, 2021</td>
                                <td>Randy’s Supermarket</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                            <tr>
                                <td className="pl-0">AUG 9, 2021</td>
                                <td>Urban Garden Supply</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                :
                <div className={getOrgName()+"-container transactions-container"}>
                    <p className="account-title">Recent transactions</p>
                    <table className="table is-fullwidth mt-5">
                        <tbody>
                            <tr>
                                <td className="pl-0">AUG 15, 2021</td>
                                <td>Your last statement is ready to view</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                            <tr>
                                <td className="pl-0">AUG 13, 2021</td>
                                <td>Interest Charge: Purchases</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                            <tr>
                                <td className="pl-0">AUG 11, 2021</td>
                                <td>Randy’s Supermarket</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                            <tr>
                                <td className="pl-0">AUG 9, 2021</td>
                                <td>Urban Garden Supply</td>
                                <td className="pr-0"><button className="account-statement-button is-clickable">View statement</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </>
        );
    }
}