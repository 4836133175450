import React from "react";
import './saresponse.scss';
import AccountOverview from "../../account/account-overview/account-overview";
import Ads from "../../account/ads/ads";
import QuickLinks from "../../account/quick-links/quick-links";
import Bundled from "../../account/bundled/bundled";
import { getAds, getOrgName, CreateLead} from "../../../utils/ads-interaction";
import RetailRecommendations from "../../account/retail-recommendations/retail-recommendations";
import { toastSuccess } from '../../../utils/toaster';
import { Link } from 'react-router-dom';
import { FaUndoAlt } from "react-icons/fa";

export default class SAResponse extends React.Component {
    constructor() {
        super();
        this.InitLead();
    }
    
    async InitLead(){
        const url = new URL(decodeURIComponent(window.location.href));
        let contactid = "NA";
        contactid = url.searchParams.get('CID');
        contactid = contactid=== null ? "NA" : contactid;
        console.log(contactid);

        let templateid = "NA";
        templateid = url.searchParams.get('TID');
        templateid = templateid=== null ? "NA" : templateid;
        
        console.log(templateid);
        if(templateid!="NA" && contactid!="NA"){
        await CreateLead(contactid, templateid);
        }
        // toastSuccess('Response Captured');
    }

    render() {
        return (
            <>
               <section className={getOrgName()+"-feedbackdetails"}>
                <div className="container" style={{"height":"100vh"}}>
                    <div className="columns ">
                        <div className="column">
                            <div class="container box p-6    has-background-light">
                            <h1 class="title has-text-centered
                                has-text-success">
                                Thank you for your interest. We'll reach out you in 2-3 days
                            </h1>
{/*                                
                               
                                <div class="buttons is-centered">
                                    <button onClick={this.SubmitStandaloneFeedback} class="button is-success is-fullwidth">
                                    Submit
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                
            </>
        );
    }

}