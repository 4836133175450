import React from "react";
import './retail.scss';
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { getRetailOffers,getOrgName, clearRetailRecord} from '../../../utils/ads-interaction';
import { Link } from "react-router-dom";
import { toastSuccess } from '../../../utils/toaster';
import RetailRecommendations from "../../account/retail-recommendations/retail-recommendations";
import { FaArrowAltCircleUp } from "react-icons/fa";

export default class Retail extends React.Component {
  
    constructor() {
        super();
        this.state = {
            retailResponse:{}
        };
        this.getRetailOffer = this.getRetailOffer.bind(this);
        this.clearRetailRecord = this.clearRetailRecord.bind(this);
    }
  
    async componentDidMount() {
      await this.getRetailOffer();
  }
  async clearRetailRecord() {        
      const response = await clearRetailRecord();
      toastSuccess('Cleared Retail Cache');       
  }

  async componentDidUpdate(prevProps, prevState) {
    // await this.getContent();
  }
  
  async getRetailOffer() {
      
      const response = await getRetailOffers();
      await this.setState({
        retailResponse: response
      })
  }
   
    render() {
        return (
            <>
            <Navbar></Navbar>
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Personalized Special Retail offers for you
                   
                    </p>
                    <p>
                    Below offers are based on your interest. 
                   </p>
                   <div>
                        <p className={getOrgName()+"-container account-title mt-6"}>Retail Offers
                    <Link to={"#"} onClick={() => this.clearRetailRecord()} >  <FaArrowAltCircleUp  style={{paddingLeft:"5px",height:"15px", width:"15px"}}/></Link></p>
                        {this.state.retailResponse && this.state.retailResponse.ContainerList 
                        && this.state.retailResponse.ContainerList.length > 0 &&
                        this.state.retailResponse.ContainerList[0].RankedResults &&
                        this.state.retailResponse.ContainerList[0].RankedResults.length > 0 ?
                        
                        <div className="columns">
                            <RetailRecommendations data={this.state.retailResponse}></RetailRecommendations>
                        </div>
                        :null}
                        {this.state.retailResponse && this.state.retailResponse.ContainerList 
                        && this.state.retailResponse.ContainerList.length > 0 &&
                        this.state.retailResponse.ContainerList[0].Status  ?
                        
                        <div className="">
                            <p>{this.state.retailResponse.ContainerList[0].Message}</p>
                        </div>
                        : null}
                    </div>
                </div>
                
            </section>
            <div   className="columns iqz-container" style={{height:"250px",margin:"0"}}>
                    </div>
            <Footer></Footer>
        </>
    );
         
    }
}
