import './retail-learn-more-details-style.scss';
import { getOrgName,getAccountURL, submitAd,triggerEvent,capturePageViewEvent, capturePageActiveTime,isUserLoggedIn, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { useState } from 'react';
import React, { useRef, useEffect } from 'react';
import { toastSuccess, toastError } from '../../../utils/toaster';
import SimilarOffers from "../../account/similar-offers/similar-offers";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FaClock} from "react-icons/fa";

export default function RetailLearnMoreDetails(props) {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const titlediv = useRef();
    let history = useHistory();

    useEffect(() => {
        titlediv.current.scrollIntoView({ behavior: 'smooth' })
        const rresponse = capturePageViewEvent(props.data);
        return () => {
          
        }
    })
    async function clickAd() {
        setIsButtonLoading(true);
        let response = await submitAd(props.data);
        let trigger = await triggerEvent(props.data);
        setIsButtonLoading(false);
        if (response.Status === 'OK') {
            toastSuccess('Thank you for showing interest.Redirecting to home page...');
            const timer = setTimeout(() => {    
                if(isUserAlreadyLoggedIn())
                {            
                    history.push(getAccountURL());
                }
                else{
                    history.push('/iqz-plus');
                }
              }, 5000);
        } else {
            toastError(`Couldn't process your request. Try again later`);
        }
    }
    
    return (
        <>
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div  ref={titlediv}  className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    {props.data.Label}
                   
                    </p>
                    <p>
                    {props.data.ShortDescription}</p>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
                 <div className="column p-0 is-four-fifths">
                     <div className="columns m-0">
                         <div className="column is-3 p-0 savings-container">
                             <p className="savings-initial-content">BRANDS AT SLASHED PRICES</p>
                         </div>
                         <div className="column is-3 p-0 savings-container savings-container-border">
                             <p className="savings-title">Savings PLUS</p>
                             <p className="savings-sub-title">Buy Best at Best price</p>
                             <p className="mt-5">More discounts</p>
                         </div>
                         <div className="column is-3 p-0 savings-container savings-container-border">
                             <p className="savings-title">Shopping PLUS</p>
                             <p className="savings-sub-title">Best EMI cost</p>
                             <p className="mt-5">No extra fees</p>
                         </div>
                         <div className="column is-3 p-0 savings-container">
                             <p className="savings-title">IQZ+ Credit Card PLUS</p>
                             <p className="savings-sub-title">Earn rewards for regular payments and low revolving balance</p>
                             
                             {/* <div className="mt-5"><FaClock/> <a  onClick={ForceEventEntry}>Waited here for 30 mins </a></div> */}
                         </div>
                         
                     </div>
                 </div>
             </section>

            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                            <img src={props.data.ImageURL} alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <p className="savings-title is-size-4">{props.data.Label}</p>
                                <p className="savings-sub-title mt-3">{props.data.Benefits}</p>
                                {isUserLoggedIn()==true?
                                    <button className={"button savings-button mt-5 "+(isButtonLoading?'is-loading':null)} onClick={clickAd}>Redeem now</button>
                                    :""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-container columns m-0 is-centered content-spacing" }>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                   
                    {(getOrgName()=='iqz' || getOrgName()=='finance')? 
                     <p className="header-content column m-auto">Happiness is not in money but in Shopping</p>
                      :
                      <p className="header-content column m-auto"> Talk to an advisor to buy insurance or Buy health insurance online. </p>
                    }
                   
                    {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Redeem now</button>:""
                    }
                    
                </div>
            </section>
            {/* <div className={getOrgName()+"-container pb-6"}>
            <p className="related-title pt-6"></p>
                <SimilarOffers  data={props.data}></SimilarOffers>
            </div> */}
        </>
    );
}