import './navbar.scss';
import { Link, useHistory } from "react-router-dom"
import AdsFeedback from "../../account/ads-feedback/ads-feedback";
import {  getOrgName, updateTealium, getLoginURL, getHomeURL, getAccountURL, getHeaderLogo } from "../../../utils/ads-interaction";
import { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";

export default function Navbar(props) {
    let history = useHistory();
    if (localStorage.getItem('data')==null){
        let data = {
            username: ""
        }
        localStorage.setItem('data', JSON.stringify(data));
       
    }
    if (localStorage.getItem('login')==null){
        let data = {
            username: ""
        }
        localStorage.setItem('login', JSON.stringify(data));
       
    }
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('data')));
    const [isModalActive, setIsModalActive] = useState(false);
    
    useEffect(() => {
       // updateTealium();
        if (localStorage.getItem('data')==null){
            let data = {
                username: ""
            }
            localStorage.setItem('data', JSON.stringify(data));
            setUserData(JSON.parse(localStorage.getItem('data')));
        }
        if(JSON.parse(localStorage.getItem('data')).username != userData.username){
            setUserData(JSON.parse(localStorage.getItem('data')));
        }
    });

    // function handleModalOpen() {
    //     setIsModalActive(true);
    // }

    function handleModalClose() {
        setIsModalActive(false);
    }

    function handleLogout(){
        setIsModalActive(true);
        // let data = {
        //     username: ""
        // }
        // localStorage.setItem('data', JSON.stringify(data));
        // this.setState({
        //     userData:  JSON.parse(localStorage.getItem('data'))
        // })
    }

    function redirectLogin() {
        let data = {
            username: ""
        }
        localStorage.setItem('data', JSON.stringify(data));
        setUserData(JSON.parse(localStorage.getItem('data')));
        history.push(getLoginURL());
        if(window.utag  === undefined || window.utag.view  === undefined){}
        else{
            window.utag.data.IQZCookieJsonStr = "NA";
        }
    }

    return (
        <>
            <nav className={"navbar px-5 navbar-"+getOrgName()} role="navigation" aria-label="main navigation">
                <div className="is-align-items-center navbar-brand">
                    {  <Link className={"navbar-item"} to={userData.username !=""? getAccountURL():getHomeURL()}>
                            <img src={getHeaderLogo()} alt="" />
                        </Link>                  
                    }
                    {getOrgName()=='iqz'?
                    <div class="navbar-start">
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class={getOrgName()+"-navbar navbar-link"} >
                        Products
                        </a>
                        <div class="navbar-dropdown">
                            <Link class="navbar-item"  to={"/mortgage"}>
                                Mortgage
                            </Link>
                            <Link class="navbar-item"  to={"/insurance"}>
                                Insurance
                            </Link>
                            <Link class="navbar-item"  to={"/investment"}>
                                Investment
                            </Link>
                            {
                                userData.username !=""?
                                <span>
                                    <Link class="navbar-item"  to={"/retail"}>
                                        Retail
                                    </Link>
                                    <Link class="navbar-item"  to={"/personalized"}>
                                        Personalized
                                    </Link>
                            {/* <Link class="navbar-item"  to={"/simulation"}>
                                Simulation
                            </Link> */}
                            </span>:
                            ""
                            }
                            
                        </div>
                    </div>
                </div>
                :
                getOrgName()=='finance'?
                <div class="navbar-start">
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class={getOrgName()+"-navbar navbar-link"} >
                    Products
                    </a>
                    <div class="navbar-dropdown">
                        <Link class="navbar-item"  to={"/iqz-finance/Cards"}>
                            Cards
                        </Link>
                        <Link class="navbar-item"  to={"/iqz-finance/Loans"}>
                            Loans
                        </Link>
                    </div>
                </div>
            </div>
            :
            getOrgName()=='primerica'?
            <div class="navbar-start">
            </div>
            :
            getOrgName()=='insurance'?
            <div class="navbar-start">
            <div class="navbar-item has-dropdown is-hoverable">
                <a class={getOrgName()+"-navbar navbar-link"} >
                Products
                </a>
                <div class="navbar-dropdown">
                    <Link class="navbar-item"  to={"/iqz-insurance/insurance"}>
                        Insurance
                    </Link>
                </div>
            </div>
        </div>:
            <div class="navbar-start">
            <div class="navbar-item has-dropdown is-hoverable">
                <a class={getOrgName()+"-navbar navbar-link"} >
                Products
                </a>
                <div class="navbar-dropdown">
                    <Link class="navbar-item"  to={"/iqz-sunlife/lifeinsurance"}>
                        Life Insurance
                    </Link>
                    <Link class="navbar-item"  to={"/iqz-sunlife/healthinsurance"}>
                        Health Insurance
                    </Link>
                    <Link class="navbar-item"  to={"/iqz-sunlife/otherinsurance"}>
                        Other Insurance
                    </Link>
                    {
                    userData.username !=""?
                    <span><Link class="navbar-item"  to={"/iqz-sunlife/personalized"}>
                        Personalized
                    </Link>
                    {/* <Link class="navbar-item"  to={"/iqz-sunlife/simulation"}>
                        Simulation
                    </Link> */}
                    </span>:
                    ""
                    }
                </div>
            </div>
        </div>}
            
            {
                userData.username != "" ?
                    <div className={getOrgName()+"-navbar field is-grouped"}><p style={{ marginTop: "7px" }} className="control ml-auto is-hidden-desktop">Welcome, {userData.username}</p>
                        <button className={"button is-primary is-family-secondary ml-auto is-hidden-desktop"} onClick={handleLogout}>Logout</button></div>
                    : <Link className={"button is-primary is-family-secondary ml-auto is-hidden-desktop"} to={"/login"}>Login {userData.username}</Link>
            }
                </div>
                <div className="navbar-end">
                    <div className="navbar-item is-hidden-touch">
                        <div className="buttons">
                            {
                                userData.username != "" ?
                                    <div className={getOrgName()+"-navbar field is-grouped"}><p style={{ marginTop: "10px" }} className="control">Welcome, {userData.username}</p>
                                        <Link className={"homeicon"} to={"/iqz-plus"}>
                                            <FaHome style={{height:"2rem", width:"2rem"}}/>
                                        </Link>
                                        <button className={"button is-primary is-family-secondary"} onClick={handleLogout}>Logout</button></div>
                                    : <Link className={"button is-primary is-family-secondary"} to={"/login"}>Login</Link>
                            }
                        </div>
                    </div>
                </div>
            </nav>
            <AdsFeedback isModalActive={isModalActive} handleModalClose={handleModalClose} redirectLogin={redirectLogin}></AdsFeedback>
        </>
    )
}