import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import  BundledItems  from "../bundledItems/bundledItems";
import { getOrgName,getAccountURL, submitAd,triggerEvent,capturePageViewEvent, capturePageActiveTime,isUserLoggedIn, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { useState } from 'react';
import React, { useRef, useEffect } from 'react';
import { toastSuccess, toastError } from '../../../utils/toaster';
import Footer from "../../common/footer/footer";
import Navbar from "../../common/navbar/navbar";

export default function Bundled(props) {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const titlediv = useRef();
    let history = useHistory();

    useEffect(() => {
        titlediv.current.scrollIntoView({ behavior: 'smooth' })
        var startDate = new Date();
        const rresponse = capturePageViewEvent(props.data.data);
        return () => {
            var endDate   = new Date();
          //  const activeSecs = capturePageActiveTime(props.data.data, (endDate.getTime() - startDate.getTime()) / 1000);
        }
    })

   async function ForceEventEntry(){
        setIsButtonLoading(true);
        let response = await capturePageActiveTime(props.data.data, 30*60);
        setIsButtonLoading(false);
        toastSuccess('Simulated 30 mins wait here');
   }
    async function clickAd() {
        setIsButtonLoading(true);
        let response = await submitAd(props.data.data);
        let trigger = await triggerEvent(props.data.data);
        setIsButtonLoading(false);
        if (response.Status === 'OK') {
            toastSuccess('Thank you for showing interest.Redirecting to home page...');
            const timer = setTimeout(() => {    
                if(isUserAlreadyLoggedIn())
                {            
                    history.push(getAccountURL());
                }
                else{
                    history.push('/iqz-plus');
                }
              }, 5000);
        } else {
            toastError(`Couldn't process your request. Try again later`);
        }
    }
    
    return (
        <>
        <Navbar></Navbar>
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div  ref={titlediv}  className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    {props.data.data.Label}
                   
                    </p>
                    <p>
                    {props.data.data.ShortDescription}</p>
                </div>
            </section>
            {(getOrgName()=='iqz' || getOrgName()=='finance')?
                 <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
                 <div className="column p-0 is-four-fifths">
                     <div className="columns m-0">
                         <div className="column is-3 p-0 savings-container">
                             <p className="savings-initial-content">Simplify your banking and save</p>
                         </div>
                         <div className="column is-3 p-0 savings-container savings-container-border">
                             <p className="savings-title">SavingsPLUS</p>
                             <p className="savings-sub-title">High every day savings rates.</p>
                             <p className="mt-5">3% interest</p>
                         </div>
                         <div className="column is-3 p-0 savings-container savings-container-border">
                             <p className="savings-title">Flex Checking</p>
                             <p className="savings-sub-title">Low minimum balance</p>
                             <p className="mt-5">No ATM fees</p>
                         </div>
                         <div className="column is-3 p-0 savings-container">
                             <p className="savings-title">IQZ+ Credit Card PLUS</p>
                             <p className="savings-sub-title">Earn rewards for regular payments and low revolving balance</p>
                             
                             {/* <div className="mt-5"><FaClock/> <a  onClick={ForceEventEntry}>Waited here for 30 mins </a></div> */}
                         </div>
                         
                     </div>
                 </div>
             </section>
             :
              <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
              <div className="column p-0 is-four-fifths">
                  <div className="columns m-0">
                      <div className="column is-3 p-0 savings-container">
                          <p className="savings-initial-content">Buy health insurance online</p>
                      </div>
                      <div className="column is-3 p-0 savings-container savings-container-border">
                          <p className="savings-title">Life Insurance</p>
                          <p className="savings-sub-title">Helps pay for health-care expenses not covered by your provincial/territorial</p>
                          {/* <p className="mt-5">3% interest</p> */}
                      </div>
                      <div className="column is-3 p-0 savings-container savings-container-border">
                          <p className="savings-title">Health Insurance</p>
                          <p className="savings-sub-title">Helps replace part of your income if you can't work.</p>
                          {/* <p className="mt-5">No ATM fees</p> */}
                      </div>
                      <div className="column is-3 p-0 savings-container">
                          <p className="savings-title">Other Insurance</p>
                          <p className="savings-sub-title">Lifelong protection from the financial impact of your death</p>
                          
                          {/* <div className="mt-5"><FaClock/> <a  onClick={ForceEventEntry}>Waited here for 30 mins </a></div> */}
                      </div>
                      
                  </div>
              </div>
          </section>
        
        }
           
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                            <img src={props.data.data.ImageURL} alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <p className="savings-title is-size-4">{props.data.data.Label}</p>
                                <p className="savings-sub-title mt-3">{props.data.data.Benefits}</p>
                                {isUserLoggedIn()==true?
                                    <button className={"button savings-button mt-5 "+(isButtonLoading?'is-loading':null)} onClick={clickAd}>Apply now</button>
                                    :""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-container columns m-0 is-centered content-spacing" }>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                   
                    {(getOrgName()=='iqz' || getOrgName()=='finance')? 
                     <p className="header-content column m-auto"> Simplify your banking and start earning more </p>
                      :
                      <p className="header-content column m-auto"> Talk to an advisor to buy insurance or Buy health insurance online. </p>
                    }
                   
                    {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                    }
                    
                </div>
            </section>
            <div className={getOrgName()+"-container pb-6"}>
                <p className="related-title pt-6">Bundled Products</p>
                <div className="columns mx-5 mt-5 pb-5">
                <BundledItems data={{data:props.data.alldata, bundleName:props.data.data.BundleName}}></BundledItems>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}