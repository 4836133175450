import './styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import LandingPage from './landing-page';
import DomainDetail from './domain-detail';
import Login from './login';
import Account from './account';
import LearnMore from './learn-more';
import RetailLearnMore from './retail-learn-more';
import Mortgage from './components/learn-more/mortgage/mortgage'
import Insurance from './components/learn-more/insurance/insurance'
import Investment from './components/learn-more/investment/investment'
import Personalized from './components/learn-more/personalized/personalized'
import Sunlife_LifeInsurance from './components/learn-more/sunlife-life-insurance/sunlife_lifeinsurance'
import Sunlife_OtherInsurance from './components/learn-more/sunlife-other-insurance/sunlife_otherinsurance'
import Sunlife_HealthInsurance from './components/learn-more/sunlife-health-insurance/sunlife_healthinsurance'
import Bundled from './components/account/bundled/bundled';
import { ToastContainer } from 'react-toastify';
import  Home  from './components/hackathon22/home/home';
import Register from './components/hackathon22/register/register';
import {Helmet} from "react-helmet";
import { isUserAlreadyLoggedIn } from "./utils/ads-interaction";
import Simulation from './components/learn-more/simulations/simulation'
import Retail from './components/learn-more/retail/retail';
import Finance_Cards from './components/learn-more/finance-cards/finance-cards';
import Finance_Loans from './components/learn-more/finance-loans/finance-loans';
import StandaloneFeedback from './components/standalone/feedback/feedback-details';
import SAResponse from './components/standalone/saresponse/saresponse';

export default class App extends React.Component {
  constructor() {
    super();
    
    this.isUserAlreadyLoggedIn = this.isUserAlreadyLoggedIn.bind(this);
}
async isUserAlreadyLoggedIn(){
  return await isUserAlreadyLoggedIn();
}
  render() {
    return (
      <>
        <Switch>
          {/* <Route path="/iqz-plus/:id" component={DomainDetail}></Route> */}
          <Route path="/iqz-plus/:id">
            <DomainDetail></DomainDetail>
          </Route>
          <Route path="/HealnHale/home">
            <Home></Home>
          </Route>
          <Route path="/HealnHale/Register">
            <Register></Register>
          </Route>
          <Route path="/iqz-plus">
            <LandingPage></LandingPage>
          </Route>

          <Route path="/feedback">
            <StandaloneFeedback></StandaloneFeedback>
          </Route>
          <Route path="/saresponse">
            <SAResponse></SAResponse>
          </Route>

          <Route path="/learn-more" render={(props) => <LearnMore data={props.location.state}></LearnMore>}>
            {/* <LearnMore></LearnMore> */}
          </Route>
          
          <Route path="/retail-learn-more" render={(props) => <RetailLearnMore data={props.location.state}></RetailLearnMore>}>

          </Route>
          <Route path="/bundled" render={(props) => <Bundled data={props.location.state}></Bundled>}>
          </Route>
          <Route path="/iqz-sunlife/bundled" render={(props) => <Bundled data={props.location.state}></Bundled>}>
          </Route>
          <Route path="/iqz-sunlife/learn-more" render={(props) => <LearnMore data={props.location.state}></LearnMore>}>
            {/* <LearnMore></LearnMore> */}
          </Route>
          <Route path="/iqz-finance/learn-more" render={(props) => <LearnMore data={props.location.state}></LearnMore>}>
            {/* <LearnMore></LearnMore> */}
          </Route>
          <Route path="/primerica/learn-more" render={(props) => <LearnMore data={props.location.state}></LearnMore>}>
            {/* <LearnMore></LearnMore> */}
          </Route>
          <Route path="/iqz-insurance/learn-more" render={(props) => <LearnMore data={props.location.state}></LearnMore>}>
            {/* <LearnMore></LearnMore> */}
          </Route>


          <Route path="/login">
            <Login></Login>
          </Route>
          
          <Route path="/iqz-sunlife/login">
            <Login ></Login>
          </Route>
          <Route path="/iqz-finance/login">
            <Login ></Login>
          </Route>
          <Route path="/primerica/login">
            <Login ></Login>
          </Route>
          <Route path="/iqz-insurance/login">
            <Login ></Login>
          </Route>

          <Route path="/mortgage">
            <Mortgage></Mortgage>
          </Route>
          <Route path="/insurance">
            <Insurance></Insurance>
          </Route>
          <Route path="/iqz-insurance/insurance">
            <Insurance></Insurance>
          </Route>
          <Route path="/investment">
            <Investment></Investment>
          </Route>
          <Route path="/personalized">
            <Personalized></Personalized>
          </Route>
          <Route path="/retail">
            <Retail></Retail>
          </Route>
          <Route path="/simulation">
            <Simulation></Simulation>
          </Route>
          <Route path="/iqz-sunlife/personalized">
            <Personalized></Personalized>
          </Route>
          <Route path="/iqz-sunlife/simulation">
            <Simulation></Simulation>
          </Route>
          <Route path="/iqz-sunlife/lifeinsurance">
            <Sunlife_LifeInsurance></Sunlife_LifeInsurance>
          </Route>
          <Route path="/iqz-sunlife/healthinsurance">
            <Sunlife_HealthInsurance></Sunlife_HealthInsurance>
          </Route>
          <Route path="/iqz-sunlife/otherinsurance">
            <Sunlife_OtherInsurance></Sunlife_OtherInsurance>
          </Route>
          <Route path="/iqz-finance/cards">
            <Finance_Cards></Finance_Cards>
          </Route>
          <Route path="/iqz-finance/loans">
            <Finance_Loans></Finance_Loans>
          </Route>
          <Route path="/iqz-insurance/loans">
            <Finance_Loans></Finance_Loans>
          </Route>
          <Route path="/account">
             <Account></Account>
          </Route>
          <Route path="/iqz-sunlife/account">
             <Account ></Account>
          </Route>
          <Route path="/iqz-finance/account">
             <Account ></Account>
          </Route>
          <Route path="/primerica/account">
             <Account ></Account>
          </Route>
          <Route path="/iqz-insurance/account">
             <Account ></Account>
          </Route>

          <Route exact path="/" render={() => { return <Redirect to="/iqz-plus" /> }} />
        </Switch>
        <Helmet>
          {/* <script type="text/javascript">{`
          var utag_data = { customer_id:"cust1" , "tealium_event":"pega_event","page_type":"Testinitv1001", customer_city:"CBE",customer_first_name:"firstnamecust", customer_email:"kiruthika.a@iqzsystems.com"     }
        
          `}
          </script> */}
          <script type="text/javascript">
          
  {            (function(a,b,c,d){
              a='https://tags.tiqcdn.com/utag/iqzsystems-sandbox/krishnan.e/prod/utag.js';
              b=document;c='script';d=b.createElement(c);d.src=a;
              d.type='text/java'+c;d.async=true;
              a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)})()}
          </script>  
          <script src="dynatrace.js"></script>       
        </Helmet>
        <ToastContainer closeButton={null} />
      </>
    );
  }
}
