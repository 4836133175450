import React from "react"
import Navbar from "./components/common/navbar/navbar"
import Header from "./components/landing-page/header/header"
import Domains from "./components/landing-page/domains/domains"
import Footer from "./components/common/footer/footer"

export default class LandingPage extends React.Component {
    render() {
        return (
            <>
                <Navbar></Navbar>
                <Header></Header>
                <Domains></Domains>
                <Footer></Footer>
            </>
        )
    }
}