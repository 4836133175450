import './styles/login.scss';
import Navbar from "./components/common/navbar/navbar";
import Footer from "./components/common/footer/footer";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { validateAssociation, getOrgName, getAccountURL, SetTealiumCustomerID } from "./utils/ads-interaction";

export default function Login(props) {
    let history = useHistory();

    return (
        <>
            <Navbar></Navbar>
            <form className={getOrgName()+'-login'} onSubmit={handleSubmit}>
                <section className="columns m-0 is-centered content-spacing">
                    <div className={getOrgName()+"-container column is-6 login-container" }>
                        <p className="has-text-centered is-size-2 has-text-weight-bold">Login</p>
                        <div className="field mt-6">
                            <label className={ "label "+getOrgName()+"-login-text"}>Username</label>
                            <div className="control">
                                <input id="username" name="username" className="input" type="text" placeholder="Username" />
                            </div>
                        </div>
                        <div className="field mt-6">
                            <label className={"label "+getOrgName()+"-login-text"}>Password</label>
                            <div className="control">
                                <input id="password" name="password" className="input" type="password" placeholder="Password" />
                            </div>
                        </div>
                        <div className="field is-grouped mt-6">
                            <div className="control">
                                <button type="submit" className="submit-button button is-link">Submit</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light clear-button">Clear</button>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
            <Footer></Footer>
        </>
    );

    async function handleSubmit(e) {
        e.preventDefault();
        let data = {
            username: e.target.elements.username.value
        }

        if(e.target.elements.username.value==""){
            alert("Please enter user name");
        }
        else if(e.target.elements.password.value != "iqzrules" ){
            alert("Password Error, Cannot login please contact the admin");
        }
        else
        {
            localStorage.setItem('data', JSON.stringify(data));
            localStorage.setItem('login', JSON.stringify(data));
            SetTealiumCustomerID();
            if(getOrgName() !='primerica'){
                const response = await validateAssociation();
            }
            const url = getAccountURL();
            history.push(url);}
    }
}