import base64 from 'react-native-base64';
import { useLocation } from 'react-router-dom';

export function getUserNameFromLocal(){
    if (localStorage.getItem('data')==null){
        let data = {
            username: ""
        }
        localStorage.setItem('data', JSON.stringify(data));
    }
    const userData = JSON.parse(localStorage.getItem('data'));
    return userData.username;
}
function getCookieUniqueKey(){
    //navigator.cookieEnabled
    //IP Address https://api.ipify.org/?format=json
    var match = document.cookie.match(new RegExp('(^| )' + 'iqzplusEXID' + '=([^;]+)'));
      if (match!=null) {
        return match[2];
      }
      else{
        const externalID = 'iqz_'+Date.now().toString(36) + Math.random().toString(36).substr(2);
        document.cookie = "iqzplusEXID="+externalID+"; path=/iqz-plus; Expires="+new Date(new Date().getTime()+1000*60*60*24*365).toGMTString();
        return externalID;
      }
}
const serverURL= getOrgName()== 'primerica'?'https://prmrca-prmagt-dt1.pegacloud.net':'https://pega-demo.iqzsystems.io';

export async function validateAssociation(){
    const requestBody = {
        SubjectID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    let response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/v1/ValidateAssociation', requestOptions);
    response = await response.json();
    if(response.Message != "Success"){
        const externalID = 'iqz_'+Date.now().toString(36) + Math.random().toString(36).substr(2);
        document.cookie = "iqzplusEXID="+externalID+"; path=/iqz-plus; Expires="+new Date(new Date().getTime()+1000*60*60*24*365).toGMTString();
    }
    return response;
}
export function SetTealiumCustomerID(){
    let custID = getUserNameFromLocal();
    window.utag.data.customer_id = custID;
    window.utag.data.EventName = "addtocart";
    window.utag.data.tealium_event = "store_event";
    window.utag.data.IQZCookieJsonStr =[];
    
    window.utag.data.IQZCookieJsonStr.push({'name':"",'type':"Login"});
   // console.log(JSON.stringify(utag_data));
    // window.utag.view({ variable1:"VARIABLE1 VALUE", variable2:"VARIABLE2 VALUE", variable3:"VARIABLE3 VALUE" });
    window.utag.view(window.utag.data);
    console.log(JSON.stringify(window.utag.data));
}
//ALWAYS return in lowercase !!!!
export function getOrgName(){
    const location = window.location.href;
    if(location.includes("sunlife"))
    {
        return "sunlife";
    }
    if(location.includes("finance"))
    {
        return "finance";
    }
    if(location.includes("iqz-insurance"))
    {
        return "insurance";
    }
    if(location.includes("HealnHale"))
    {
        return "pega";
    }
    if(location.includes("primerica"))
    {
        return "primerica";
    }
    return "iqz";
}

export function getHomeURL(){
    return "/iqz-plus";
}
export function getLoginURL(){
    if(getOrgName()=='sunlife'){
        return "/iqz-sunlife/login";
    }
    if(getOrgName()=='finance'){
        return "/iqz-finance/login";
    }    
    if(getOrgName()=='primerica'){
        return "/primerica/login";
    }
    if(getOrgName()=='insurance'){
        return "/iqz-insurance/login";
    }
    return "/login";
}
export function getAccountURL(){
    if(getOrgName()=='sunlife'){
        return "/iqz-sunlife/account";
    }
    if(getOrgName()=='finance'){
        return "/iqz-finance/account";
    }
    
    if(getOrgName()=='primerica'){
        return "/primerica/account";
    }
    if(getOrgName()=='insurance'){
        return "/iqz-insurance/account";
    }
    return "/account";
}
export function getWalletUrl(){
    if(getOrgName()=='sunlife'){
        return "/images/wallet.svg";
    }
    if(getOrgName()=='finance'){
        return "/images/fn-Credit.svg";
    }
    if(getOrgName()=='primerica'){
        return "/images/fn-Credit.svg";
    }
    return "/images/low-account-minimum.svg";
}
export function getHeaderLogo(){
    if(getOrgName()=='sunlife'){
        return "/images/sunlife-white.svg";
    }
    return "/images/logo-top.svg";
}
export function getFooterLogo(){
    if(getOrgName()=='sunlife'){
        return "/images/sunlife-logo-footer.svg";
    }
    if(getOrgName()=='finance'){
        return "/images/fn-logo-footer.svg";
    }
    if(getOrgName()=='primerica'){
        return "/images/fn-logo-footer.svg";
    }
    if(getOrgName()=='insurance'){
        return "/images/fn-logo-footer.svg";
    }
    return "/images/logo-footer.svg";
}
export function getFooterTwitterLogo(){
    if(getOrgName()=='finance'){
        return "/images/twitter-square.svg" ;
    }
    if(getOrgName()=='primerica'){
        return "/images/twitter-square.svg" ;
    }
    if(getOrgName()=='insurance'){
        return "/images/twitter-square.svg" ;
    }
    return "/images/twitter-square.svg" ;
}
export function getFooterLnLogo(){
    if(getOrgName()=='finance'){
        return "/images/fn-linkedin.svg" ;
    }
    if(getOrgName()=='primerica'){
        return "/images/fn-linkedin.svg" ;
    }
    if(getOrgName()=='insurance'){
        return "/images/fn-linkedin.svg" ;
    }
    return "/images/linkedin.svg";
}
export function getLearnmoreURL(){
    if(getOrgName()=='sunlife'){
        return "/iqz-sunlife/learn-more";
    }
    if(getOrgName()=='finance'){
        return "/iqz-finance/learn-more";
    }    
    if(getOrgName()=='primerica'){
        return "/primerica/learn-more";
    }
    if(getOrgName()=='insurance'){
        return "/iqz-insurance/learn-more";
    }
    return "/learn-more";
}
export function getBundledURL(){
    if(getOrgName()=='sunlife'){
        return "/iqz-sunlife/bundled";
    }
    return "/bundled";
}
export function getTealiumVisitorID()
{
    if(window.utag  === undefined || window.utag.view  === undefined){
        console.log("utag not ready");
        // setTimeout(50000);
        // getTealiumVisitorID();
    }
    else{
        return window.utag.data.tealium_visitor_id;
    }
}
export async function getAds() {
    const objValue = getOrgName();
    const VistorIDTealium = getTealiumVisitorID();
    
    let requestBody = {
        SubjectID: getUserNameFromLocal(),        
        CustomerID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
        ContextName: 'Customer',
        ContainerName: "CDHOffers",
       // ContainerName: 'WebTopOffers',
        Channel: 'Web',
        Direction: 'Inbound',
        Contexts: [
            {
            "Type": "CurrentPage",
            "Value": "WebOffers",
             "Key": "Intend"
           }, {
            "Type": "CurrentPage",
            "Value": objValue,
             "Key": "Org"
           },
           {
            "Type": "CurrentPage",
            "Value": VistorIDTealium,
             "Key": "VisitorID"
           },
        ]
    }
    if(objValue==="finance"){
        requestBody = {
            SubjectID: getUserNameFromLocal(),        
            CustomerID: getUserNameFromLocal(),
            ExternalID: getCookieUniqueKey(),
            ContextName: 'Customer',
            ContainerName: "CreditCardOffers",
           // ContainerName: 'WebTopOffers',
            Channel: 'Web',
            Direction: 'Inbound',
            Contexts: [
                {
                "Type": "CurrentPage",
                "Value": VistorIDTealium,
                 "Key": "VisitorID"
               },
            ]
        }
    }
    
    if(objValue==="primerica"){
        requestBody = {
            SubjectID: getUserNameFromLocal(),        
            CustomerID: getUserNameFromLocal(),
            ExternalID: getCookieUniqueKey(),
            ContextName: 'Customer',
            ContainerName: "IQZNextBestOffer",
           // ContainerName: 'WebTopOffers',
            Channel: 'Web',
            Direction: 'Inbound',
            Contexts: [
                {
                "Type": "CurrentPage",
                "Value": "WebOffers",
                 "Key": "Intend"
               }, {
                "Type": "CurrentPage",
                "Value": "iqz",
                 "Key": "Org"
               },
               {
                "Type": "CurrentPage",
                "Value": VistorIDTealium,
                 "Key": "VisitorID"
               },
            ]
        }
    }
    if(objValue==="insurance"){
        requestBody = {
            SubjectID: getUserNameFromLocal(),        
            CustomerID: getUserNameFromLocal(),
            ExternalID: getCookieUniqueKey(),
            ContextName: 'Customer',
            ContainerName: "InsuranceContainer",
           // ContainerName: 'WebTopOffers',
            Channel: 'CS',
            Direction: 'Inbound',
            Contexts: [
                {
                "Type": "CurrentPage",
                "Value": VistorIDTealium,
                 "Key": "VisitorID"
               },
            ]
        }
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    let response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V4/Container', requestOptions);
    response = await response.json();
    return response;
}
export async function getRetailOffers() {
    
    const requestBody = {
        SubjectID: getUserNameFromLocal(),        
        CustomerID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
        ContextName: 'Customer',
        ContainerName: "RetailContainer",
        Channel: 'Web',
        Direction: 'Inbound'
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    let response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V4/Container', requestOptions);
    response = await response.json();
    return response;
}

export async function getCustom() {
    const objValue = getOrgName();
    
    const requestBody = {
        SubjectID: getUserNameFromLocal(),        
        CustomerID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
        ContextName: 'Customer',
        ContainerName: "Personalized",
       // ContainerName: 'WebTopOffers',
        Channel: 'Web',
        Direction: 'Inbound',
        Contexts: [
            {
            "Type": "CurrentPage",
            "Value": "Personalized",
             "Key": "Context"
           }, {
            "Type": "CurrentPage",
            "Value": objValue,
             "Key": "Org"
           },
        ]
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    let response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V4/Container', requestOptions);
    response = await response.json();
    return response;
}

export async function clearCache() {
   
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic '  + base64.encode('SupportUser' + ":" + 'rules@123') }
    };
    let response = await fetch(serverURL+'/prweb/api/v1/nodes/all/caches/conclusion/clear?source=memory', requestOptions);
    response = await response.json();
    return response;
}
export async function getSimilarProducts(data) {
    let objValue = getOrgName();
    if(objValue === "insurance"){
        objValue = "iqz";
    }
    const requestBody = {
        SubjectID: getUserNameFromLocal(),
        CustomerID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
        ContextName: 'Customer',
      //  ContainerName: 'SimilarOffers',
        ContainerName: 'NBASimilarOffers',
        Channel: 'Web',
        Direction: 'Inbound',
        Contexts: [{
            "Type": "CurrentPage",
            "Value": data.Name,
             "Key": "Offer"
           },
           {
            "Type": "CurrentPage",
            "Value": data.Group,
            "Key": "Group"
           },
           {
            "Type": "CurrentPage",
            "Value": data.ParentGroup,
            "Key": "ParentGroup"
           },
           {
            "Type": "CurrentPage",
            "Value": data.ParentGroup == ""? data.Group:data.ParentGroup,
            "Key": "RelevantGroup"
           },
            {
            "Type": "CurrentPage",
            "Value": "SimilarProducts",
             "Key": "Context"
           },
           {
            "Type": "CurrentPage",
            "Value": objValue,
             "Key": "Org"
           },
        ]
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    // let response ={};
    let response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V4/Container', requestOptions);
    response = await response.json();
    return response;
}

export async function captureClick(data) {
    let response;
    if( getOrgName() =="finance"){
        window.utag.data.tealium_event = "store_event";
        window.utag.data.EventName = "ViewOffer";
        window.utag.data.customer_id = getUserNameFromLocal();
        if(isUserLoggedIn()){
            if(window.utag  === undefined || window.utag.view  === undefined){
                console.log("utag not ready");
            }
            else{
                let json ={};
                console.log(window.utag.data.IQZCookieJsonStr);
                if(window.utag.data.IQZCookieJsonStr == undefined)
                {
                    window.utag.data.IQZCookieJsonStr =[];
                }
                let newObj = {}
                newObj['name'] = data.Name;
                newObj['type'] = "Clicked";

                window.utag.data.IQZCookieJsonStr.push(newObj);
                window.utag.view(window.utag.data);
            }
        }
    }
    
    const requestBody = {
        SubjectID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
        ContextName: data.ContextName,
        //ContainerName: "WebTopOffers",
        ContainerName: "CDHOffers",
        RankedResults: [{
            SubjectID: getUserNameFromLocal(),
            ContextName: data.ContextName,
            Name: data.Name,
            Issue: data.Issue,
            Group: data.Group,
            Rank: data.Rank,
            CampaignID: data.CampaignID,
            InteractionID: data.InteractionID,
            Outcome: "Clicked",
            Channel: "Web",
            Direction: "Inbound",
            Journey: "",
            JourneyStage: "",
            JourneyStep: ""
        }]
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V3/CaptureResponse/Initiate', requestOptions);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}


export async function submitAd(data) {
    let response;
    
    const requestBody = {
        SubjectID: getUserNameFromLocal(),
        ExternalID: getCookieUniqueKey(),
        ContextName: data.ContextName,
        //ContainerName: "WebTopOffers",
        ContainerName: "CDHOffers",
        RankedResults: [{
            SubjectID: getUserNameFromLocal(),
            ContextName: data.ContextName,
            Name: data.Name,
            Issue: data.Issue,
            Group: data.Group,
            Rank: data.Rank,
            CampaignID: data.CampaignID,
            InteractionID: data.InteractionID,
            Outcome: "Accepted",
            Channel: "Web",
            Direction: "Inbound",
            Journey: "",
            JourneyStage: "",
            JourneyStep: ""
        }]
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V3/CaptureResponse/Initiate', requestOptions);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}

export async function triggerEvent(data) {
    let response;
    
    const requestBody = {
        CustomerID: getUserNameFromLocal(),
        EventName: "TriggerOutboundCampaign"        
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/CustomMarketingArtifacts/V1/Campaign/TriggerRTEvent', requestOptions);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}

export async function capturePageViewEvent(data) {
    let response;
   let hyphen="-";
    let col =":";
    
    const requestBody = {
        CustomerID: getUserNameFromLocal(),
        Event: "PageView",
        EventTimestamp: new Date().toISOString().replaceAll(hyphen, '').replaceAll(col, '').replace('Z',' GMT'),
        PageType: "LearnMore",
        InterestedIn:   data.Group  
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/v1/stream/WebDataStream', requestOptions);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    } 
   
    return response;
}

export async function capturePageActiveTime(group, seconds) {
    let response;
    let hyphen="-";
    let col =":";
    seconds = Math.round(seconds);
   // callTealium(getUserNameFromLocal(), "PageView", group, group, "120");
    const requestBody = {
        CustomerID: getUserNameFromLocal(),
        Event: "PageView",
        EventTimestamp: new Date().toISOString().replaceAll(hyphen, '').replaceAll(col, '').replace('Z',' GMT'),
        PageType: "LearnMore",
        InterestedIn:   group,
        PageViewActiveTime: seconds
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/v1/stream/WebDataStream', requestOptions);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    
    return response;
}
export async function simulateCustomerActivity(Type,Amount,Location,Reason, Comment) {
    let response;
    const requestBody = {
        CustomerID: getUserNameFromLocal(),
        Amount: Amount,
        Location: Location,
        Reason:Reason,
        Comment:Comment,
        Type: Type
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/V3/MockData', requestOptions);
        response = await response.json();
        
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}
export async function simulateDailyEvent(ID, EventName,EventType,Weight) {
    let response;
    const requestBody = {
        Identifier: ID,
        EventName: EventName,
        EventType:EventType,
        pyCount:Weight
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/v1/stream/DailyEventLog', requestOptions);
        response = await response.json();
        
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}
export async function getCustomMsg() {
    let response;
    const requestBody = {
        CustomerID: getUserNameFromLocal()
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/V3/GetNurturingMessage', requestOptions);
        response = await response.json();
        
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}
export async function getCutomerActivityRecords() {
    let response;
    const requestBody = {
        CustomerID: getUserNameFromLocal()
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/V3/GetCustomerActivity', requestOptions);
        response = await response.json();
        
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}
export async function triggerGeofence(Latitude,Longitude) {
    let response;
    let GeofenceURL = "?CustomerID="+ getUserNameFromLocal()+"&DeviceID=1009p&DeviceLatitude="+Latitude+"&DeviceLongitude="+Longitude;
 
    const requestOptions = {
        method: 'POST',
        headers: { },
    };
    try {
        response = await fetch(serverURL+'/prweb/PRHTTPService/PegaMKTGeolocation/Services/HandleMktGeolocations'+GeofenceURL, requestOptions);
        response = await response.json();
        
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}
//Login in localstorage will not be cleared on logout
export function isUserLoggedIn(){
    if (localStorage.getItem('login')==null){
        let login = {
            username: ""
        }
        localStorage.setItem('login', JSON.stringify(login));
    }
    let UserDetails = JSON.parse(localStorage.getItem('login'));
    if(UserDetails.username!=""){
        return true;
    }
    else{
        return false;
    }
}

export function isUserAlreadyLoggedIn(){
    if (localStorage.getItem('data')==null){
        let login = {
            username: ""
        }
        localStorage.setItem('data', JSON.stringify(login));
    }
    let UserDetails = JSON.parse(localStorage.getItem('data'));
    if(UserDetails.username!=""){
        return true;
    }
    else{
        return false;
    }
}
export function callTealium(custID,EventParam, PageNameParam, OfferNameParam, ActiveTimeParam){
    var utag_data = { customer_id:custID , 
    "tealium_event":"pega_event",
    "page_type":"Testinit",
     CustomerID:custID,
     PegaEvent: EventParam,
     PageName: PageNameParam,
     OfferName: OfferNameParam,
     ActiveTime:ActiveTimeParam
    }
   // console.log(JSON.stringify(utag_data));
    // window.utag.view({ variable1:"VARIABLE1 VALUE", variable2:"VARIABLE2 VALUE", variable3:"VARIABLE3 VALUE" });
    window.utag.view(utag_data);
}
export async function updateTealium(custID,EventParam, PageNameParam, OfferNameParam, ActiveTimeParam){
    const location = window.location.href;
    if(isUserLoggedIn()){
        if(window.utag  === undefined || window.utag.view  === undefined){
            console.log("utag not ready");
        }
        else{
            window.utag.data.tealium_event = "pega_event";
            window.utag.data.EventName = "menuclick";
            window.utag.data.customer_id = getUserNameFromLocal();
            var pathName = window.location.pathname;
            let json ={};
            console.log(window.utag.data.IQZCookieJsonStr);
            if(window.utag.data.IQZCookieJsonStr != "NA" && window.utag.data.IQZCookieJsonStr != undefined){
                json = JSON.parse(window.utag.data.IQZCookieJsonStr);
                if(json.hasOwnProperty(pathName))
                {
                    let valueOf = parseInt(json[pathName]) + 1;
                    json[pathName] = valueOf;
                    window.utag.data.event_attr_1 = valueOf;
                    window.utag.data.IQZCookieJsonStr = JSON.stringify(json);
                    if(valueOf > 1)
                    {
                       window.utag.view(window.utag.data);
                    }
                }
                else
                {
                    json[pathName] = 1;
                    window.utag.data.IQZCookieJsonStr = JSON.stringify(json);
                }
            }
            else
            {
                json[pathName] = 1;
                window.utag.data.IQZCookieJsonStr = JSON.stringify(json);
            }
            
        }
        
    }
}
export function updateGeoData(Cord){
    console.log(Cord);
}

export async function clearRetailRecord() {
    const requestBody = {
        CustomerID: getUserNameFromLocal()
    };
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
    };
   
    let response = await fetch(serverURL+'/prweb/api/PegaMKTContainer/V1/DeleteCustomerActivity', requestOptions);
    response = await response.json();
    return response;
}
export async function SubmitStandaloneFeedback(CustID, Q1Ans, Q2Ans) {
    let response;
    
    const requestBody = {
        "SubjectID": CustID,
        "ContextName": "Customer",
        "ContainerName": "WebTopOffers",
        "RankedResults": [
            {
            "SubjectID": CustID,
            "ContextName": "Customer",
            "Name": "AgentQuestionnaire_Cycle1",
            "Issue": "Sales",
            "Group": "Agent",
            "Rank": 1,
            "Outcome": "AnswerCaptured",
            "Channel": "Retail",
            "Direction": "Inbound",
            "Journey": "",
            "JourneyStage": "",
            "JourneyStep": "",
            "MKTType": "Question1",
            "MKTValue": Q1Ans
            },
            {
                "SubjectID": CustID,
                "ContextName": "Customer",
                "Name": "AgentQuestionnaire_Cycle1",
                "Issue": "Sales",
                "Group": "Agent",
                "Rank": 2,
                "Outcome": "AnswerCaptured",
                "Channel": "Retail",
                "Direction": "Inbound",
                "Journey": "",
                "JourneyStage": "",
                "JourneyStep": "",
                "MKTType": "Question2",
                "MKTValue": Q2Ans
            }
        ]
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    try {
        response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V3/CaptureResponse', requestOptions);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}

export async function CreateLead(CustID, TemplateID) {
    let response;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        response = await fetch(serverURL+'/prweb/api/SAApis/v1/CreateLead/'+CustID+'/'+TemplateID);
        response = await response.json();
    } catch(err) {
        return {
            Status: 'Failed',
            Message: 'The API call failed'
        };
    }
    return response;
}