// react
import React, { useState, useEffect } from 'react';
import './map.scss';
// openlayers
import GeoJSON from 'ol/format/GeoJSON'
import Feature from 'ol/Feature';

// components
import MapWrapper from './MapWrapper'
export default function Map()
{
    // set intial state
  const [ features, setFeatures ] = useState([])

  // initialization - retrieve GeoJSON features from Mock JSON API get features from mock 
  //  GeoJson API (read from flat .json file in public directory)
  useEffect( () => {

    fetch('/mock-geojson-api.json')
      .then(response => response.json())
      .then( (fetchedFeatures) => {

        // parse fetched geojson into OpenLayers features
        //  use options to convert feature from EPSG:4326 to EPSG:3857
        const wktOptions = {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }
        const parsedFeatures = new GeoJSON().readFeatures(fetchedFeatures, wktOptions)

        // set features into state (which will be passed into OpenLayers
        //  map component as props)
        setFeatures(parsedFeatures)

      })

  },[])
  
  return (
    <div className="App">
      
      {/* <div className="app-label">
        <p>React Functional Components with OpenLayers Example</p>
        <p>Click the map to reveal location coordinate via React State</p>
      </div> */}
      {/* <div class=" field is-grouped is-grouped-centered">
      <p class="control">
      <input id= "Latitude" class="input" type="text" placeholder="Latitude"/>
      <input id= "Longitude" class="input" type="text" placeholder="Longitude"/>
      </p>
                                  
      </div> */}
      <div style={{ display: "none" }}>
          {/* Marker */}
          <div 
          id="marker" 
          style={{
            width: "20px",
            height: "20px",
            border: "1px solid #088",
            borderRadius: "10px",
            backgroundColor: "#0FF"
          }}
          >
            </div>
            
          </div>
      <MapWrapper features={features}>
     
      </MapWrapper>

    </div>
  )
}