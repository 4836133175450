import './footer-style.scss';
import React from "react";
import { Link } from 'react-router-dom';
import { clearCache, getFooterLogo, getOrgName, getFooterLnLogo, getFooterTwitterLogo } from "../../../utils/ads-interaction";
import { toastSuccess } from '../../../utils/toaster';
import { FaArrowAltCircleUp } from "react-icons/fa";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        
        this.clearCache = this.clearCache.bind(this);
        // this.getOrgName = this.getOrgName.bind(this);
    }
    async clearCache() {        
        const response = await clearCache();
        toastSuccess('Cleared Cache');       
    }
    render() {
        return (
            <>
                <footer className={"footer "+getOrgName()+"-footer"}>
                    <div className="pl-5">
                        { 
                         <img src={getFooterLogo()} width={144} alt="" />
                        }
                    </div>
                    <div className="pl-5 mt-6">
                        <span className={getOrgName()+"-footer-text"}>
                            <Link to={"/"} onClick={() => this.clearCache()} >  <FaArrowAltCircleUp/></Link>
                        </span>
                        <span className={getOrgName()+"-footer-text"}>© 2023 IQZ+ All rights reserved</span>
                        <span className={getOrgName()+"-footer-text"}>
                            <Link to={"/"}>HOME</Link>
                        </span>
                        <span className={getOrgName()+"-footer-text"}>
                            <Link to={"/"}>GET IN TOUCH</Link>
                        </span>
                        <span className={getOrgName()+"-footer-text"}>
                            <Link to={"/"} >TERMS AND CONDITIONS</Link>
                        </span>
                       
                        <span className="social-icons">
                            <Link to={"/"}>
                                <img src={getFooterTwitterLogo()} alt="" />
                            </Link>
                        </span>
                        <span className="social-icons">
                            <Link to={"/"}>
                                <img src={getFooterLnLogo()} alt="" />
                            </Link>
                        </span>
                    </div>
                </footer>
            </>
        );
    }
}