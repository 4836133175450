import "./quick-links-style.scss";
import React from "react";
import { getOrgName } from "../../../utils/ads-interaction";
import RequestForm from "../request-form/request-form";
import { useState, useEffect } from "react";

export default function QuickLinks (props) {
    const [isFormActive, setIsFormActive] = useState(false);
    function handleRequest(){
        setIsFormActive(true);
    }
    function handleFormClose() {
        setIsFormActive(false);
    }
        return (
            <>
            {getOrgName()=='primerica'?
                <div className={getOrgName()+"-container links-container"}>
                    <p className="account-title">Quick Links</p>
                    <div className="mt-4">
                        <span className="m-0"><button className="quick-links-button is-clickable" onClick={handleRequest}>Request a service</button></span>
                        {/* <span className="m-0"><button className="quick-links-button">Make a payment</button></span>
                        <span className="m-0"><button className="quick-links-button">Update billing</button></span>
                        <span className="m-0"><button className="quick-links-button">Proof of insurance</button></span>
                        <span className="m-0"><button className="quick-links-button">Update your profile</button></span> */}
                    </div>
                </div>
                :
                <div className={getOrgName()+"-container links-container"}>
                    <p className="account-title">Quick Links</p>
                    <div className="mt-4">
                        <span className="m-0"><button className="quick-links-button">Request a service</button></span>
                        <span className="m-0"><button className="quick-links-button">Make a payment</button></span>
                        <span className="m-0"><button className="quick-links-button">Update billing</button></span>
                        <span className="m-0"><button className="quick-links-button">Proof of insurance</button></span>
                        <span className="m-0"><button className="quick-links-button">Update your profile</button></span>
                    </div>
                </div>
            }
             <RequestForm isFormActive={isFormActive} handleFormClose={handleFormClose} ></RequestForm>
            </>
            
        )
    }
