import React from "react";
import './account-details.scss';
import AccountOverview from "../account-overview/account-overview";
import Ads from "../ads/ads";
import QuickLinks from "../quick-links/quick-links";
import Bundled from "../bundled/bundled";
import { getAds, getOrgName, getRetailOffers, clearRetailRecord } from "../../../utils/ads-interaction";
import RetailRecommendations from "../retail-recommendations/retail-recommendations";
import { toastSuccess } from '../../../utils/toaster';
import { Link } from 'react-router-dom';
import { FaUndoAlt } from "react-icons/fa";

export default class AccountDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: {},
            retailResponse:{}
        };
        this.getContent = this.getContent.bind(this);
    }
    
    async componentDidMount() {
        this.getContent();
    }

    render() {
        return (
            <>
                <section className={getOrgName()+"-accountdetails"}>
                    <div className="container">
                        <div className="columns m-0">
                            <div className="column">
                                <AccountOverview data={this.state.response}></AccountOverview>
                            </div>
                            <div className="column is-one-third">
                            <Ads data={this.state.response}></Ads>
                            <Link to={"#"} onClick={() => this.getContent()} >  
                            <FaUndoAlt  style={{"float":"right"}}/></Link>
                                
                                <QuickLinks></QuickLinks>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    async getContent() {
        const response = await getAds();
        let Setplacement = false;
        if(response.ContainerList[0] && response.ContainerList[0].RankedResults){
            for(let i = 0; i < response.ContainerList[0].RankedResults.length; i++) {
                let obj = response.ContainerList[0].RankedResults[i];
                if(!Setplacement && ((obj.BundleName==="") || (obj.BundleParent==="true"))){
                    Setplacement = true;
                    response.ContainerList[0].RankedResults[i].Placement = "Tile1";
                }
            }
            await this.setState({
                response: response
            })
        }

    }
}