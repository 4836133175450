import React from "react";
import './personalized.scss';
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { getCustomMsg, getCutomerActivityRecords, simulateDailyEvent, simulateCustomerActivity,triggerGeofence,getCustom, getLearnmoreURL,getOrgName, isUserAlreadyLoggedIn, captureClick } from '../../../utils/ads-interaction';
import { toastSuccess, toastError } from '../../../utils/toaster';
import { Link, useHistory } from "react-router-dom";
import { FaPhoneSquare, FaShoppingCart, FaUndoAlt} from "react-icons/fa";

export default class Personalized extends React.Component {
  
    constructor() {
        super();
        this.reset();
        //this.history = useHistory();
        this.getContent = this.getContent.bind(this);
        this.formatDate = this.formatDate.bind(this);

        this.getCutomerActivityRecords = this.getCutomerActivityRecords.bind(this);
        this.getCustomMsg = this.getCustomMsg.bind(this);
        this.SimluateRecord = this.SimluateRecord.bind(this);
        this.SimluateEventRecord = this.SimluateEventRecord.bind(this);
        this.DeleteRecord = this.DeleteRecord.bind(this);
        this.updateTypeValue = this.updateTypeValue.bind(this);
        this.adDetail = this.adDetail.bind(this);
        this.LocationMetaData = [
            { name: "Boston", Latitude: 42.3601, Longitude: -71.0589 },
            { name: "Boston nearby", Latitude: 42.3609,  Longitude: -71.06018},
            { name: "Georgia", Latitude: 32.1656, Longitude: 82.9001 },
            {name:"Georgia nearby", Latitude:32.194402, Longitude:-82.801795},
            { name: "New York", Latitude: 40.7128, Longitude: 74.0060 },
            {name:"New York nearby", Latitude:40.711834, Longitude:-74.005466}
          ];
    }
  
    async componentDidMount() {
      await this.getContent();
      await this.getCustomMsg();
      await this.getCutomerActivityRecords();
      this.reset();
  }

  async componentDidUpdate(prevProps, prevState) {
    // await this.getContent();
  }
  async getCustomMsg() {
      
    const response = await getCustomMsg();
    //  alert(JSON.stringify(response));
     await this.setState({
      responseMsg: response,
      displayNotification:true
    })
}
async getCutomerActivityRecords() {
      
  const response = await getCutomerActivityRecords();
   await this.setState({
    records: response
  })
}
  async getContent() {
      
      const response = await getCustom();
      await this.setState({
          response: response
      })
  }
    reset() {

        this.state = {
          responseMsg:'',
          records:'',
          Type: 'na',
          Amount:'na',
          Location:'na',
          Latitude:'',
          Reason:'na',
          Comment:'na',
          ID:'',
          isButtonLoading:false,
          EventName:'na',
          EventWeight :'',
          EventDesc:'',
          displayNotification:false
        };
      }
      updateEventName(evt) {
        const EventID = evt.target.value;
        const val = evt.target[evt.target.selectedIndex].text;
        this.setState({
          EventName: val,
          ID: EventID
        });
      }
      updateEventWeight(evt) {
        const val = evt.target.value;
        this.setState({
          EventWeight: val
        });
      }
      updateEventDesc(evt) {
        const val = evt.target.value;
        this.setState({
          EventDesc: val
        });
      }
      updateTypeValue(evt) {
        const val = evt.target.value;
        this.setState({
          Type: val
        });
      }
      updateLocationValue(evt) {
        const val = evt.target.value;
        let Latitude = '45';
        let Longitude ='';
        for (let i = 0; i < this.LocationMetaData.length; i++) {
            if(this.LocationMetaData[i].name==val){
                Latitude = this.LocationMetaData[i].Latitude;
                Longitude =  this.LocationMetaData[i].Longitude;
            }
          }
        this.setState({
          Location: val,
          Latitude :Latitude,
          Longitude: Longitude
        });
      }
      updateAmountValue(evt) {
        const val = evt.target.value;
        this.setState({
          Amount: val
        });
      }
      updateReasonValue(evt) {
        const val = evt.target.value;
        this.setState({
          Reason: val
        });
      }
      updateCommentValue(evt) {
        const val = evt.target.value;
        this.setState({
          Comment: val
        });
      }
    async SimluateRecord() {
       // let response = await capturePageActiveTime("Investment", 2*60);
        this.setState({
          isButtonLoading:true
        });
        let response = await simulateCustomerActivity(this.state.Type,this.state.Amount,this.state.Location,this.state.Reason,this.state.Comment);
        response = await triggerGeofence(this.state.Latitude,this.state.Longitude);
        toastSuccess('Simulated record...');
        this.setState({
          isButtonLoading:false
        });
        await this.getCutomerActivityRecords();
        await this.getContent();
        await this.getCustomMsg();
        //this.reset();
    }
    async SimluateEventRecord() {
       this.setState({
         isButtonLoading:true
       });
       let response = await simulateDailyEvent(this.state.ID,this.state.EventName,this.state.EventDesc,this.state.EventWeight);
       this.reset();
       toastSuccess('Simulated Event record...');
       this.setState({
         isButtonLoading:false,
         EventDesc : '',
         EventWeight : '',
         ID : ''
       });
   }
    async DeleteRecord() {
      // let response = await capturePageActiveTime("Investment", 2*60);
       this.setState({
         isButtonLoading:true
       });
       let response = await simulateCustomerActivity("ClearRecordsForCustomer",this.state.Amount,this.state.Location,this.state.Reason,this.state.Comment);
       response = await triggerGeofence(this.state.Latitude,this.state.Longitude);
       toastSuccess('Cleared record...');
       this.setState({
         isButtonLoading:false
       });
       await this.getCutomerActivityRecords();
       await this.getContent();
       await this.getCustomMsg();
       //this.reset();
   }
    hideNotification(){
      this.setState({displayNotification:false})
    }
    async adDetail(data) {
      const response = await captureClick(data);
      // history.push(getLearnmoreURL(), props.data.ContainerList[0].RankedResults[0]);
      // this.history.push(getLearnmoreURL(), data);
  //     const app = (props) => {
  //       const history = useHistory();
  //       history.push(getLearnmoreURL(), data);
  //  }
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      
   //alert(d.getHours()+":"+d.getMinutes());
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    day = day + " "+d.getHours()+":"+d.getMinutes();
    return [year, month, day].join('-');
}
    render() {
        return (
            <>
            <Navbar></Navbar>
            {/* {(this.state.responseMsg && this.state.responseMsg !=="" && this.state.responseMsg.pyMessage!="Null")?
               <div class={"notification is-primary is-light " + (this.state.displayNotification?'displayNotification':'hideNotification')}>
               <button class="delete" onClick={()=>this.setState({displayNotification:false})}></button>
               {this.state.responseMsg.pyMessage}
             </div>:
             ""
            } */}
           
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Personalized Special offers for you
                   
                    </p>
                    <p>
                    Below offers are based on the your activity currently targetting the location transaction data. 
                   </p>
                </div>
            </section>

            {isUserAlreadyLoggedIn()==true ?
                <section className={getOrgName()+"-container content-spacing m-0 is-centered" }>
                  
                 {
                 getOrgName()=="iqz"?
                      <section className="page-background columns m-0 content-spacing" style={{ padding:"5rem"}}>
                      <div className="column p-0 is-three-fifths is-family-code ">
                          <label className={getOrgName()+"-header-content column m-auto"} >
                          Simulate the record 
                          </label>
                          {/* <div class=" field is-grouped is-grouped-centered">
                              <label class={getOrgName()+"-dropdownLabel"}>Location</label>
                              <div class="select iqzdropdown">
                              <select id="location" onChange={evt => this.updateLocationValue(evt)}>
                                  <option>Select Location</option>
                                  <option value="Boston">Boston</option>
                                  <option value="Geogria">Geogria</option>
                                  <option value="Newyork">Newyork</option>
                              </select>
                              </div>
                              <label class={getOrgName()+"-dropdownLabel"}>Type</label>
                              <div class="select iqzdropdown">
                              <select id="Type" onChange={evt => this.updateTypeValue(evt)}>
                                  <option>Select Type</option>
                                  <option value="Purchase">Purchase</option>
                                  <option value="checkin">Check-in</option>
                                  <option value="csr">CSR Call</option>
                              </select>
                            
                              </div>
                              <p class="control">
                                  
                                  <input id= "amount" class="input" type="text" placeholder="Comment" onChange={evt => this.updateAmountValue(evt)}/>
                              </p>
                              <button class={"button savings-button " +(this.state.isButtonLoading?'is-loading':null)} onClick={this.SimluateRecord}>
                                  Simulate
                                  </button>
                          </div> */}
                          {/* {isUserLoggedIn()==true?
                                          <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                          }
                          */}
                           <div class=" field is-grouped is-grouped-centered">
                              <label class={getOrgName()+"-dropdownLabel"}></label>
                              <div class="select iqzdropdown">
                              <select id="EventName" value={this.state.ID} onChange={evt => this.updateEventName(evt)}>
                                  <option>Select Event</option>
                                  <option value="1001">Mortgage</option>
                                  <option value="1002">Insurance</option>
                                  <option value="1003">Investment</option>
                              </select>
                              </div>
                              <label class={getOrgName()+"-dropdownLabel"}></label>
                              <p class="control">
                                  
                                  <input id= "EventWeight" value={this.state.EventWeight} class="input" placeholder="Weight"  type="text" onChange={evt => this.updateEventWeight(evt)}/>
                              </p>
                            
                              {/* <label class={getOrgName()+"-dropdownLabel"}>Amount</label> */}
                              <p class="control">
                                  
                                  <input id= "EventDesc" value={this.state.EventDesc} class="input" type="text" placeholder="Description" onChange={evt => this.updateEventDesc(evt)}/>
                              </p>
                              <button class={"button savings-button " +(this.state.isButtonLoading?'is-loading':null)} onClick={this.SimluateEventRecord}>
                                  Simulate
                                  </button>
                          </div>
                        </div>
                      </section> 

                          :
                      <section className="page-background columns m-0 content-spacing" style={{ padding:"5rem"}}>
                          <div className="column p-0 is-three-fifths is-family-code ">
                              <label className={getOrgName()+"-header-content column m-auto"} >
                              Simulate the record
                              </label>
                              <div class=" field is-grouped is-grouped-centered">
                                  
                                  {/* <label class={getOrgName()+"-dropdownLabel"}>Type</label> */}
                                  <div class="select iqzdropdown">
                                  <select id="Type" onChange={evt => this.updateTypeValue(evt)}>
                                      <option>Select Type</option>
                                      <option value="Purchase">Purchase</option>
                                      {/* <option value="checkin">Check-in</option> */}
                                      <option value="csr">CSR Call</option>
                                  </select>                               
                                  </div>
                                  <div class="select iqzdropdown">
                                  <select id="Reason" onChange={evt => this.updateReasonValue(evt)}>
                                      <option>Select Reason</option>
                                      <option value="Sales">Sales</option>
                                      <option value="Service">Service</option>
                                      <option value="Complaints">Complaints</option>
                                  </select>
                                  </div>
                                  {/* <label class={getOrgName()+"-dropdownLabel"}>Amount</label> */}
                                  <p class="control">
                                      
                                      <input id= "Comment" class="input" type="text" placeholder="Comment" onChange={evt => this.updateCommentValue(evt)}/>
                                  </p>
                                  
                              </div>
                              <div class=" field is-grouped is-grouped-centered">
                              <button style={{margin:"1rem"}} class={"button " +(this.state.isButtonLoading?'is-loading':null)} onClick={this.SimluateRecord}>
                                      Simulate
                                      </button>
                                      </div>
                              {/* {isUserLoggedIn()==true?
                                              <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                              }
                              */}
                          </div>
                          <div  className="column p-0">
                          { this.state.records && this.state.records.pxResults && (this.state.records.pxResults).length>0 ?
                              <div className={getOrgName()+"-container"}>
                                <label className={getOrgName()+"-header-content column m-auto"} >
                                Recent Records <span class={"icon is-small "+(this.state.isButtonLoading?' is-loading':null)} onClick={this.DeleteRecord}><FaUndoAlt/></span>
                                </label>
                            {this.state.records.pxResults.map((data) => {
                              return (
                                  <div className="columns is-multiline m-5">
                                  {data.Type==='csr'?
                                  <div><FaPhoneSquare/>  {this.formatDate(data.pxUpdateDateTime)} {data.Reason} {data.Comment}</div> :
                                  ""
                                  }
                                  {data.Type==='purchase'?
                                  <div><FaShoppingCart/>  {this.formatDate(data.pxUpdateDateTime)} {data.Reason} {data.Comment}</div> :
                                  ""
                                  }
                                  </div>
                              )
                          })}
                            </div>
                              :
                              <div className={getOrgName()+"-container"}>
                                <label className={getOrgName()+"-header-content column m-auto"} >
                                No Recent Records...
                                </label></div>
                          }
                          </div>
                      </section>
              }
              <div className="columns is-multiline is-centered mx-5 mt-5 pb-5">
                     { this.state.response && this.state.response.ContainerList && this.state.response.ContainerList[0].RankedResults && (this.state.response.ContainerList[0].RankedResults).length>0 ?
                        <div className={getOrgName()+"-container"}>
                          
                       {this.state.response.ContainerList[0].RankedResults.map((data) => {
                        return (
                            <div className="columns is-multiline is-centered m-5">
                            <div className="column m-5" key={data.Name} title={"Propensity: "+data.Propensity }>
                                <div>
                                    <img style={{ width: "500px", height:"350px", float:"right"}} className="domain-image" src={data.ImageURL} alt="" />
                                </div>
                                </div>
                                <div className="column m-5" >
                                <div>
                                    <p className="topic">{data.Label}</p>
                                    <p className="is-size-6 is-family-code has-text-weight-light mt-2">{data.ShortDescription}</p>
                                    <p className="is-size-6 is-family-code has-text-weight-light mt-2">{data.Benefits}</p>
                                    {/* <button id={data.Name} className={"button account-statement-button mt-4 is-clickable"} onClick={() => adDetail(data)}>Learn more</button> */}
                                    <Link to={{  pathname: getLearnmoreURL(),  state: data }}  onClick={() => this.adDetail(data)}  id={data.Name} className="button mt-4 secondary-button">Learn more</Link>
                                </div>
                            </div>
                            </div>
                        )
                    })}
                       </div>
                        :
                        null
                     }
                     
                 </div>
            </section> 
                            
            :""}
            
            <Footer></Footer>
        </>
    );
         
    }
}
