import React from "react";
import "./ads-feedback-style.scss";
import { toastSuccess, toastError } from '../../../utils/toaster';
import { getOrgName } from "../../../utils/ads-interaction";
const DEFAULT_SLIDER_VALUE = 5;

export default class AdsFeedback extends React.Component {
    constructor() {
        super();
        this.state = {
            slider_1_value: DEFAULT_SLIDER_VALUE,
            slider_2_value: DEFAULT_SLIDER_VALUE,
            slider_3_value: DEFAULT_SLIDER_VALUE,
            isButtonLoading: false
        };
        this.changeSliderValue = this.changeSliderValue.bind(this);
        this.resetValues = this.resetValues.bind(this);
        this.submitFeedback = this.submitFeedback.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.redirectLogin = this.redirectLogin.bind(this);
    }

    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({
    //             modalVisible: true
    //         });
    //     }, 5000);
    // }

    changeSliderValue(event, slider) {
        let value = event.target.value;
        if (slider === 1) {
            this.setState({
                slider_1_value: value
            });
        } else if (slider === 2) {
            this.setState({
                slider_2_value: value
            });
        } else if (slider === 3) {
            this.setState({
                slider_3_value: value
            });
        }
    }

    resetValues() {
        this.setState({
            slider_1_value: DEFAULT_SLIDER_VALUE,
            slider_2_value: DEFAULT_SLIDER_VALUE,
            slider_3_value: DEFAULT_SLIDER_VALUE
        });
    }

    async submitFeedback() {
        await this.setState({
            isButtonLoading: true
        });
        var result = {
            Loan: document.getElementById('slider-1').value,
            Insurance: document.getElementById('slider-2').value,
            Investment: document.getElementById('slider-3').value,
        };
        if(getOrgName()=='sunlife')
        {
            result={};
            result = {
                Lifeinsurance: document.getElementById('slider-1').value,
                HealthInsurance: document.getElementById('slider-2').value,
                OtherInsurance: document.getElementById('slider-3').value,
            };
        }
        if(getOrgName()=='primerica')
        {
            result={};
            result = {
                Mortgage: document.getElementById('slider-1').value,
                Insurance: document.getElementById('slider-2').value,
                Investment: document.getElementById('slider-3').value,
            };
        }
        if(getOrgName()=='finance')
        {
            result={};
            result = {
                Loan: document.getElementById('slider-1').value,
                Cards: document.getElementById('slider-2').value,
                Investment: document.getElementById('slider-3').value,
            };
        }
        const values = Object.keys(result);
        const responses = new Set();
        const userData = JSON.parse(localStorage.getItem('data'));
        for (let i = 0; i < values.length; i++) {
            let response;
            const requestBody = {
                SubjectID: userData.username,
                ContextName: "Customer",
                ContainerName: "WebTopOffers",
                RankedResults: [{
                    SubjectID: userData.username,
                    ContextName: "Customer",
                    Name: "Feedback",
                    Issue: "Sales",
                    Group: "Service",
                    Rank: 1,
                    Outcome: "FeedbackCaptured",
                    Channel: "Web",
                    Direction: "Inbound",
                    Journey: "",
                    JourneyStage: "",
                    JourneyStep: "",
                    MKTType: values[i],
                    MKTValue: result[values[i]]
                }]
            };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody)
            };
            let serverURL = getOrgName()== 'primerica'?'https://prmrca-prmagt-dt1.pegacloud.net':'https://pega-demo.iqzsystems.io';
            try {
                response = await fetch(serverURL+'/prweb/PRRestService/PegaMKTContainer/V3/CaptureResponse', requestOptions);
                response = await response.json();
                if (response.Status === "OK") {
                    responses.add(true);
                }
            } catch (err) {
                responses.add(false);
            }
        }
        await this.setState({
            isButtonLoading: false,
            modalVisible: false
        });
        if (responses.has(false)) {
            toastError('There was an error in submitting the values. Please try again later');
        } else {
            toastSuccess('Feedback successful. Thank you!');
            this.props.redirectLogin();
        }
        return;
    }

    handleModalClose() {
        this.props.handleModalClose();
    }

    redirectLogin() {
        this.props.redirectLogin();
    }

    render() {
        if(getOrgName()=='iqz'|| getOrgName()=='primerica'){
            return (
                <div className={"modal " + (this.props.isModalActive ? 'is-flex' : null)}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Help us with your feedback</p>
                            <button className="delete" aria-label="close" onClick={this.handleModalClose}></button>
                        </header>
                        <section className="modal-card-body pt-0 pb-0">
    
                            <div className="menu">
                                <ul className="menu-list">
                                    <li className="custom-menu-feedback-list">
                                        <ul>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the mortgage products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-1" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_1_value}
                                                        onChange={(event) => this.changeSliderValue(event, 1)} />
                                                    <output className="slider-custom-output" htmlFor="slider-1">{this.state.slider_1_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the insurance products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-2" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_2_value}
                                                        onChange={(event) => this.changeSliderValue(event, 2)} />
                                                    <output className="slider-custom-output" htmlFor="slider-2">{this.state.slider_2_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the investment products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-3" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_3_value}
                                                        onChange={(event) => this.changeSliderValue(event, 3)} />
                                                    <output className="slider-custom-output" htmlFor="slider-3">{this.state.slider_3_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="button-custom-container">
                                                <button className={"button slider-custom-submit mr-4 " + (this.state.isButtonLoading ? 'is-loading' : null)}
                                                    onClick={this.submitFeedback}>Submit</button>
                                                <button className="button" onClick={this.resetValues}>Clear</button>
                                            </div></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <div className="button-custom-container">
                                <button className="button slider-custom-submit" onClick={this.redirectLogin}>Logout</button>
                            </div>
                        </footer>
                    </div>
                </div>
            );
        }
        else if(getOrgName()=='finance' ) {
            return (
                <div className={"modal " + (this.props.isModalActive ? 'is-flex' : null)}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Help us with your feedback</p>
                            <button className="delete" aria-label="close" onClick={this.handleModalClose}></button>
                        </header>
                        <section className="modal-card-body pt-0 pb-0">
    
                            <div className="menu">
                                <ul className="menu-list">
                                    <li className="custom-menu-feedback-list">
                                        <ul>
                                            {/* For Finance */}
                                            
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the Loan products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-1" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_1_value}
                                                        onChange={(event) => this.changeSliderValue(event, 1)} />
                                                    <output className="slider-custom-output" htmlFor="slider-1">{this.state.slider_1_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the Card products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-2" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_2_value}
                                                        onChange={(event) => this.changeSliderValue(event, 2)} />
                                                    <output className="slider-custom-output" htmlFor="slider-2">{this.state.slider_2_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the other Investment products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-3" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_3_value}
                                                        onChange={(event) => this.changeSliderValue(event, 3)} />
                                                    <output className="slider-custom-output" htmlFor="slider-3">{this.state.slider_3_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="button-custom-container">
                                                <button className={"button slider-custom-submit mr-4 " + (this.state.isButtonLoading ? 'is-loading' : null)}
                                                    onClick={this.submitFeedback}>Submit</button>
                                                <button className="button" onClick={this.resetValues}>Clear</button>
                                            </div></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <div className="button-custom-container">
                                <button className="button slider-custom-submit" onClick={this.redirectLogin}>Logout</button>
                            </div>
                        </footer>
                    </div>
                </div>
            );
        }        
        else{
            return (
                <div className={"modal " + (this.props.isModalActive ? 'is-flex' : null)}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Help us with your feedback</p>
                            <button className="delete" aria-label="close" onClick={this.handleModalClose}></button>
                        </header>
                        <section className="modal-card-body pt-0 pb-0">
    
                            <div className="menu">
                                <ul className="menu-list">
                                    <li className="custom-menu-feedback-list">
                                        <ul>
                                            {/* For Sunlife */}
                                            
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the life insurance products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-1" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_1_value}
                                                        onChange={(event) => this.changeSliderValue(event, 1)} />
                                                    <output className="slider-custom-output" htmlFor="slider-1">{this.state.slider_1_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the health insurance products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-2" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_2_value}
                                                        onChange={(event) => this.changeSliderValue(event, 2)} />
                                                    <output className="slider-custom-output" htmlFor="slider-2">{this.state.slider_2_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="field">
                                                <label className="label">Rate the other insurance products offered by IQZ+</label>
                                                <div className="control is-flex">
                                                    <input id="slider-3" className="slider is-fullwidth" step="1" min="0" max="10" type="range" value={this.state.slider_3_value}
                                                        onChange={(event) => this.changeSliderValue(event, 3)} />
                                                    <output className="slider-custom-output" htmlFor="slider-3">{this.state.slider_3_value}</output>
                                                </div>
                                            </div></li>
                                            <li className="custom-menu-feedback-list"><div className="button-custom-container">
                                                <button className={"button slider-custom-submit mr-4 " + (this.state.isButtonLoading ? 'is-loading' : null)}
                                                    onClick={this.submitFeedback}>Submit</button>
                                                <button className="button" onClick={this.resetValues}>Clear</button>
                                            </div></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <div className="button-custom-container">
                                <button className="button slider-custom-submit" onClick={this.redirectLogin}>Logout</button>
                            </div>
                        </footer>
                    </div>
                </div>
            );
        }
        
    }
}