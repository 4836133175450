import React from "react";
import './finance-cards-style.scss';
import { Link } from 'react-router-dom';
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { FaClock} from "react-icons/fa";
import {  callTealium, getUserNameFromLocal, getOrgName, capturePageActiveTime, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { toastSuccess, toastError } from '../../../utils/toaster';

export default class Finance_Cards extends React.Component {
    constructor() {
        super();
        
        this.ForceEventEntry = this.ForceEventEntry.bind(this);
    }
    async ForceEventEntry(){
        let response = await capturePageActiveTime("Cards", 2*60);
        toastSuccess('Simulated 2 mins wait here');
   }
   async ForceCDPEntry(){
    let response = await callTealium(getUserNameFromLocal(),"PageView","Cards", "Cards", 2*60);
    toastSuccess('Simulated 2 mins wait here via Tealium');
}
    render() {
        return (
            <>
            <Navbar></Navbar>
            <div className="finance-products-container">
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Cards Offer
                   
                    </p>
                    <p>
                    This quick personal loan can accomplish your plans such as marriage, vacations, home renovation and much more.</p>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-initial-content">Apply card online</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">MoneyBack+ Credit Card</p>
                            <p className="savings-sub-title">The Most Rewarding Card For Everyday Spends.5X CashPoints on EMI spends at merchant locations</p>
                            <p className="mt-5">Rewards cards </p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Luxurious Indulgences</p>
                            <p className="savings-sub-title">1% cashback on all other spends (except Fuel) including EMI & Wallet transactions</p>
                            <p className="mt-5">Platinum Cards</p>
                        </div>
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-title">Travel benefits</p>
                            <p className="savings-sub-title">complimentary international lounge access using Priority Pass</p>
                            
                            {isUserAlreadyLoggedIn()==true?
                            <div style={{color:"black"}}  className=" columns mt-5">
                            <div className="column"><FaClock/> <a style={{color:"black"}}  onClick={this.ForceCDPEntry}>2 mins via Tealium </a></div>
                            <div className="column"><FaClock/> <a style={{color:"black"}}  onClick={this.ForceEventEntry}>2 mins via Pega Stream </a></div>
                            </div>
                            :
                            ""}
                            </div>
                        
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                        <img src="/images/financeCards.jpg" alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <p className="savings-title is-size-4">IQZ Finance Cards</p>
                                <p className="savings-sub-title mt-3">Whether it’s for shopping, travelling, entertainment or bills, we make payments every day. An IQZ Card always make these moments better.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className={getOrgName()+"-container columns m-0 is-centered content-spacing" }>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Talk to an advisor to buy suitable cards for your need.
                    </p>
                    {/* {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                    }
                     */}
                </div>
            </section>
            </div>
            <Footer></Footer>
        </>
    );
         
    }
}
