import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import React from "react";
import SimilarItems from "../similar-items/similar-items";
import { getSimilarProducts } from "../../../utils/ads-interaction";

export default class SimilarOffers extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            response: {}
        };
        this.getContent = this.getContent.bind(this);
        const name = this.props.data.name;
    }

    async componentDidMount() {
        this.name = this.props.data.Name;
        await this.getContent();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.name !== this.props.data.Name) {
            this.name = this.props.data.Name;
            await this.getContent();
        }
    }
    async getContent() {
        
        const response = await getSimilarProducts(this.props.data);
        await this.setState({
            response: response
        })
    }
    render() {
        return (
            <>
             {this.state.response && this.state.response.ContainerList  
                            && this.state.response.ContainerList.length > 0 &&
                            this.state.response.ContainerList[0].RankedResults &&
                            this.state.response.ContainerList[0].RankedResults.length > 0 ?
                        <p className="related-title pt-6">Related Products</p>:
                        null
                     }
                 <div className="columns mx-5 mt-5 pb-5">
                
                     {this.state.response?
                        <SimilarItems data={this.state.response}></SimilarItems>:
                        null
                     }
                     
                 </div>
            </>
        );
    }
}