import "./styles/learn-more-style.scss";
import React from "react";
import Navbar from "./components/common/navbar/navbar";
import Footer from "./components/common/footer/footer";
import RetailLearnMoreDetails from "./components/learn-more/retail-learn-more-details/retail-learn-more-details";

export default class RetailLearnMore extends React.Component {
    render() {
        return (
            <>
                <Navbar></Navbar>
                <RetailLearnMoreDetails data={this.props.data}></RetailLearnMoreDetails>
                <Footer></Footer>
            </>
        );
    }
}