import React from "react";
import { Link } from 'react-router-dom';
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { FaClock} from "react-icons/fa";
import {  callTealium, getUserNameFromLocal, getOrgName, capturePageActiveTime, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { toastSuccess, toastError } from '../../../utils/toaster';

export default class Sunlife_HealthInsurance extends React.Component {
    constructor() {
        super();
        
        this.ForceEventEntry = this.ForceEventEntry.bind(this);
    }
    async ForceEventEntry(){
        let response = await capturePageActiveTime("HealthInsurance", 2*60);
        toastSuccess('Simulated 2 mins wait here');
   }
   async ForceCDPEntry(){
    let response = await callTealium(getUserNameFromLocal(),"PageView","HealthInsurance", "HealthInsurance", 2*60);
    toastSuccess('Simulated 2 mins wait here via Tealium');
}
    render() {
        return (
            <>
            <Navbar></Navbar>
           .
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Health insurance
                   
                    </p>
                    <p>
                    Health insurance is personal insurance coverage that protects your savings
                     from the many healthcare costs not covered by government plans.  
                     There are 4 different types of health insurance products that provide the
                      protection you need at each stage in life. An advisor can help you understand how
                       these products fit into your financial plan.</p>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-initial-content">Critical illness insurance calculator</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Personal health insurance</p>
                            <p className="savings-sub-title">Helps pay for health-care expenses not covered by your provincial/territorial</p>
                            <p className="mt-5"> employer plan.</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Disability insurance</p>
                            <p className="savings-sub-title">Helps replace part of your income if you can't work.</p>
                            <p className="mt-5">insurance</p>
                        </div>
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-title">Critical illness insurance</p>
                            <p className="savings-sub-title">Helps cover the costs of coping with and recovering from a covered critical illness.</p>
                            
                            {isUserAlreadyLoggedIn()==true?
                            <div style={{color:"black"}}  className=" columns mt-5">
                            <div className="column"><FaClock/> <a style={{color:"black"}}   onClick={this.ForceCDPEntry}>2 mins via Tealium </a></div>
                            <div className="column"><FaClock/> <a  style={{color:"black"}}  onClick={this.ForceEventEntry}>2 mins via Pega Stream </a></div>
                            </div>
                            :
                            ""}
                            </div>
                        
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                        <img src="/images/SAHealthInsurance.jpg" alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <p className="savings-title is-size-4">Health Insurance</p>
                                <p className="savings-sub-title mt-3">Don’t let financial worries complicate a difficult situation or limit your options. 
                                Get the coverage you need.
                                You choose the plan with the health benefits that best suit your personal drug,
                                 dental and supplementary health and wellness needs.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className={getOrgName()+"-container columns m-0 is-centered content-spacing" }>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Talk to an advisor to buy insurance or Buy health insurance online.
                    </p>
                    {/* {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                    }
                     */}
                </div>
            </section>
            <Footer></Footer>
        </>
    );
         
    }
}
