import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { captureClick, getLearnmoreURL } from "../../../utils/ads-interaction";

export default function SimilarItems(props) {
    let history = useHistory();
    async function adDetail(data) {
        const response = await captureClick(data);
        history.push(getLearnmoreURL(), data);
    }

    if (Object.keys(props.data).length !== 0 && (props.data.ContainerList[0].hasOwnProperty("RankedResults"))) {
        return (
            <>
                {
                    props.data.ContainerList[0].RankedResults.map((data) => {
                        return (
                            <div className="column m-5" key={data.Name} title={"Propensity: "+data.Propensity }>
                                <div>
                                    <img style={{ width: "350px", height:"250px"}} className="domain-image" src={data.ImageURL} alt="" />
                                </div>
                                <div>
                                    <p className="topic">{data.Label}</p>
                                    <p className="is-size-6 is-family-code has-text-weight-light mt-2">{data.ShortDescription}</p>
                                    <button id={data.Name} className={"button account-statement-button mt-4 is-clickable"} onClick={() => adDetail(data)}>Learn more</button>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        );
    } else {
        return (
            <>
                <div className="" style={{ width: "100%" }}>
                    <div className="loader-wrapper" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        {/* <img className="img-loader" src='/images/loader.gif' alt='' style={{ margin: "auto" }} /> */}
                        <button className={"button account-statement-button mt-4 is-clickable"}>Will get you more related offers soon</button>
                    </div>
                </div>
            </>
        );
    }
}