import React from "react";
import { Link } from 'react-router-dom';
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { FaClock} from "react-icons/fa";
import {  callTealium, getUserNameFromLocal, getOrgName, capturePageActiveTime, isUserAlreadyLoggedIn } from '../../../utils/ads-interaction';
import { toastSuccess, toastError } from '../../../utils/toaster';

export default class Sunlife_LifeInsurance extends React.Component {
    constructor() {
        super();
        
        this.ForceEventEntry = this.ForceEventEntry.bind(this);
    }
    async ForceEventEntry(){
        let response = await capturePageActiveTime("Lifeinsurance", 2*60);
        toastSuccess('Simulated 2 mins wait here');
   }
   async ForceCDPEntry(){
    let response = await callTealium(getUserNameFromLocal(),"PageView","Lifeinsurance", "Lifeinsurance", 2*60);
    toastSuccess('Simulated 2 mins wait here via Tealium');
}
    render() {
        return (
            <>
            <Navbar></Navbar>
           .
            <section className={ getOrgName()+"-container columns m-0 is-centered content-spacing"}>
                <div   className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Life Insurance                  
                    </p>
                    <p>
                    Life insurance is coverage that gives your family or beneficiaries a tax-free cash payment
                     ( also called a death benefit) after you die. This way, they can help take care of their financial needs
                      even after you’re gone. Life insurance helps protect the financial security of the people who are 
                      depending on you.</p>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered" }>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-initial-content">Buy health insurance online</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Term life insurance</p>
                            <p className="savings-sub-title">You can buy term life insurance that covers you for</p>
                            <p className="mt-5">10, 15 or 20 or more years</p>
                        </div>
                        <div className="column is-3 p-0 savings-container savings-container-border">
                            <p className="savings-title">Permanent life insurance</p>
                            <p className="savings-sub-title">Lifelong protection from the financial impact of your death</p>
                            <p className="mt-5">financial impact</p>
                        </div>
                        <div className="column is-3 p-0 savings-container">
                            <p className="savings-title">Whole life insurance</p>
                            <p className="savings-sub-title">Sun Life Go Guaranteed Life Insurance</p>
                            
                            {isUserAlreadyLoggedIn()==true?
                            <div style={{color:"black"}}  className=" columns mt-5">
                            <div className="column"><FaClock/> <a style={{color:"black"}}  onClick={this.ForceCDPEntry}>2 mins via Tealium </a></div>
                            <div className="column"><FaClock/> <a style={{color:"black"}}  onClick={this.ForceEventEntry}>2 mins via Pega Stream </a></div>
                            </div>
                            :
                            ""}
                            </div>
                        
                    </div>
                </div>
            </section>
            <section className={getOrgName()+"-container content-spacing columns m-0 is-centered"}>
                <div className="column p-0 is-four-fifths">
                    <div className="columns m-0">
                        <div className="column p-0 savings-image-container-wrapper">
                        <img src="/images/SALifeInsurance.jpg" alt=''/>
                        </div>
                        <div className="column p-0 savings-image-container-wrapper">
                            <div className="savings-image-container">
                                <p className="savings-title is-size-4">Life Insurance</p>
                                <p className="savings-sub-title mt-3">There’s no “one-size-fits-all” type of life insurance.
                                 The kind your parents have, or that your friend just 
                                bought, may not be the best kind for you. To help choose the right type of life insurance.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className={getOrgName()+"-container columns m-0 is-centered content-spacing" }>
                <div className="column p-0 is-three-fifths is-family-code has-text-centered">
                    <p className="header-content column m-auto">
                    Talk to an advisor to buy insurance or Buy health insurance online.
                    </p>
                    {/* {isUserLoggedIn()==true?
                                    <button className="button savings-button mt-5" onClick={clickAd}>Apply now</button>:""
                    }
                     */}
                </div>
            </section>
            <Footer></Footer>
        </>
    );
         
    }
}
