import './styles/domain-detail-style.scss'
import React from "react";
import Navbar from './components/common/navbar/navbar';
import Footer from './components/common/footer/footer';
import { Link, useHistory } from "react-router-dom";
import { getAds } from "./utils/ads-interaction";

export default class DomainDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: {}
        };
        this.getContent = this.getContent.bind(this);
    }
    async componentDidMount() {
        await this.getContent();
    }
    async getContent() {
      const response = await getAds();
      await this.setState({
          response: response
      })
    }
    render() {
        if (Object.keys(this.state.response).length !== 0 && (this.state.response.ContainerList[0].hasOwnProperty("RankedResults"))) {
            return (
                <>
                    {/* {this.state.response.match.params.id}  use this to get the route dynamically */}
                    <Navbar></Navbar>
                    <section className="page-background">
                        <div className="container">
                        <div className="columns content-container">
                            <div className="column is-one-fifth">
                                <p className="topic">Retail</p>
                                <p className="sub-topic mt-2">Set goal,<br /> save more.</p>
                                <button className="button mt-2">Learn more</button>
                            </div>
                            <div className="column">
                                <div className="columns m-0"> {
                                    this.state.response.ContainerList[0].RankedResults.map((data) => {
                                        return (
                                            <div className="column" key={data.Name}>
                                                <div>
                                                    <img style={{ width: "300px", height: "250px" }} className="domain-image" src={data.ImageURL} alt="" />
                                                </div>
                                                <div>
                                                    <p className="topic">{data.Label}</p>
                                                    <p className="is-size-6 is-family-code has-text-weight-light mt-2">{data.ShortDescription}</p>
                                                    <Link to={{  pathname: "/learn-more",  state: data }}  id={data.Name} className="button mt-4 secondary-button">Learn more</Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                } </div>
                                <div className="columns bottom-container">
                                    <div className="column is-5 bottom-first-column">
                                        <p className="bottom-topic">We’re here to help</p>
                                        <p className="is-size-6 is-family-code has-text-weight-light mt-2">We focus on delivering the best service.</p>
                                        <button className="button mt-4">Contact us</button>
                                    </div>
                                    <div className="column is-1"></div>
                                    <div className="column is-5 bottom-second-column">
                                        <p className="topic">Go paperless</p>
                                        <p className="is-size-6 is-family-code has-text-weight-light mt-2">A safe and secure way to stay organized.</p>
                                        <button className="button mt-4 secondary-button">Learn more</button>
                                    </div>
                                </div></div>
                        </div>
                     </div>
                    </section>
                    <Footer></Footer>
                </>
            );
        }
        else {
            return (
                <>
                    {/* {this.state.response.match.params.id}  use this to get the route dynamically */}
                    <Navbar></Navbar>
                    <section className="page-background">
                        <div className="container">
                        <div className="columns content-container">
                            <div className="column is-one-fifth">
                                <p className="topic">Retail</p>
                                <p className="sub-topic mt-2">Set goal,<br /> save more.</p>
                                <button className="button mt-2">Learn more</button>
                            </div>
                            <div className="column">
                                <div className="columns m-0">
                                    <div className="column">
                                        <div>
                                            <img style={{ width: "300px", height: "250px" }}  className="domain-image" src="/images/best.jpeg" alt="" />
                                        </div>
                                        <div>
                                            <p className="topic">Plan for their future</p>
                                            <p className="is-size-6 is-family-code has-text-weight-light mt-2">Give the kids a head start.</p>
                                            <button className="button mt-4 secondary-button">Learn more</button>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div>
                                            <img style={{ width: "300px", height: "250px" }}  className="domain-image" src="/images/best1.jpg" alt="" />
                                        </div>
                                        <div>
                                            <p className="topic">Bank simply</p>
                                            <p className="is-size-6 is-family-code has-text-weight-light mt-2">Easy access. At your fingertips.</p>
                                            <button className="button mt-4 secondary-button">Learn more</button>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div>
                                            <img style={{ width: "300px", height: "250px" }}  className="domain-image" src="/images/best3.jpg" alt="" />
                                        </div>
                                        <div>
                                            <p className="topic">Shop for a mortgage</p>
                                            <p className="is-size-6 is-family-code has-text-weight-light mt-2">Finance your dream home.</p>
                                            <button className="button mt-4 secondary-button">Learn more</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns bottom-container">
                                    <div className="column is-5 bottom-first-column">
                                        <p className="bottom-topic">We’re here to help</p>
                                        <p className="is-size-6 is-family-code has-text-weight-light mt-2">We focus on delivering the best service.</p>
                                        <button className="button mt-4">Contact us</button>
                                    </div>
                                    <div className="column is-1"></div>
                                    <div className="column is-5 bottom-second-column">
                                        <p className="topic">Go paperless</p>
                                        <p className="is-size-6 is-family-code has-text-weight-light mt-2">A safe and secure way to stay organized.</p>
                                        <button className="button mt-4 secondary-button">Learn more</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </>
            );
        }
    }
}