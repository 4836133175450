import './domains-style.scss';
import React from "react";
import { Link } from 'react-router-dom';

export default class Domains extends React.Component {
    render() {
        return (
            <>
                <section className="page-background content-spacing">
                    <p className="has-text-centered domain-title">The IQZ+ application</p>
                </section>
                <section style={{paddingBottom: '4rem'}} className="page-background content-spacing">
                    <ul className="card-grid">
                        <a href="https://store.iqzplus.com/" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/iqz-Store.svg" alt="" />
                                    </div>
                                    <p>IQZPlus Retail Store</p>
                                </div>
                            </li>
                        </a>
                       
                        <Link to="/iqz-finance/login" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/financeDomain.png" alt="" />
                                    </div>
                                    <p>Finance</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/iqz-sunlife/login" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/insurance.svg" alt="" />
                                    </div>
                                    <p>Sunlife Insurance</p>
                                </div>
                            </li>
                        </Link>
                        <a href="https://blog.iqzplus.com/" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/IQZBlog.svg" alt="" />
                                    </div>
                                    <p>Blog</p>
                                </div>
                            </li>
                        </a>
                        <Link to="/primerica/login" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                    <img src="/images/Primerica.jpg" alt="" />
                                    </div>
                                    <p>Primerica Insurance</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/iqz-insurance/login" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/logo-top.svg" alt="" />
                                    </div>
                                    <p>IQZPlus Insurance</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/iqz-plus/retail" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/life-science-for-patients.svg" alt="" />
                                    </div>
                                    <p>Life science for patients</p>
                                </div>
                            </li>
                        </Link>
                        
                        <a href="https://finance.iqzplus.com/" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/logo-top.svg" alt="" />
                                    </div>
                                    <p>IQZPlus Finance</p>
                                </div>
                            </li>
                        </a>
                        <a href="https://pega-demo.iqzsystems.io/prweb/PRAuth/app/PLS/" target="_blank" rel="noreferrer">
                           <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/plstileicon.svg" alt="" />
                                    </div>
                                    <p>Policy Life Servicing</p>
                                </div>
                            </li>
                        </a>
                        <a href="https://pega-demo.iqzsystems.io/prweb/PRAuth/app/ml/" target="_blank" rel="noreferrer">
                           <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/mortgageTileIcon.png" alt="" />
                                    </div>
                                    <p>Mortgage</p>
                                </div>
                            </li>
                        </a>
                        <a href="https://pega-demo.iqzsystems.io/prweb/app/iqzsa/" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/auto.svg" alt="" />
                                    </div>
                                    <p>Sales demo</p>
                                </div>
                            </li>
                        </a>
                        <Link to="/HealnHale/home" target="_blank" rel="noreferrer">
                            <li>
                                <div>
                                    <div className="image-container">
                                        <img src="/images/hackathonHealAndHaleWhite.svg" alt="" />
                                    </div>
                                    <p>Heal n Hale</p>
                                </div>
                            </li>
                        </Link>
                    </ul>
                </section>
            </>
        );
    }
}