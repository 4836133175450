import "./styles/learn-more-style.scss";
import React from "react";
import Navbar from "./components/common/navbar/navbar";
import Footer from "./components/common/footer/footer";
import LearnMoreDetails from "./components/learn-more/learn-more-details/learn-more-details";

export default class LearnMore extends React.Component {
    render() {
        return (
            <>
                <Navbar></Navbar>
                <LearnMoreDetails data={this.props.data}></LearnMoreDetails>
                <Footer></Footer>
            </>
        );
    }
}